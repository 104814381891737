import {PrinterFileDto, PrinterFileModel, PrinterFileRelationsBluePrint} from "./PrinterTypes";
import {PRINTER_URL} from "../../config/config";
import useHttp from "../../lib/samfe/modules/Http/useHttp";

const usePrinterFile = () => useHttp<PrinterFileModel, PrinterFileDto, PrinterFileRelationsBluePrint>({
    baseUrl: PRINTER_URL,
    endpoint: `print`,
    withCredentials: false
});

export default usePrinterFile;