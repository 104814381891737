import { API_URL } from '../../config/config';
import { FileType } from './DocumentTypes';


/**
 * For viewing uploaded files.
 *
 * @returns {void}
 */
export const viewPdf = (id: string|number|undefined, fileType: FileType): void => {
    setTimeout(() => {
        window.open(`${ API_URL }/api/v1/documents/${ fileType }/${ id }`, '_blank', 'noreferrer');
    }, 200);
};