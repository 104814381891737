/* eslint-disable no-use-before-define */
import {ArticleModel} from "../article/ArticleTypes";
import {SaleRowModel} from "../sale/pivot/SaleRowTypes";
import {SaleModel} from "../sale/SaleTypes";
import {CustomerModel} from "../customer/CustomerTypes";
import {ProducerModel} from "../producer/ProducerTypes";
import {RepackingModel} from "../repacking/RepackingTypes";
import { SamfeStockItem, StockChargeModel } from '../stock/StockTypes';
import {PurchaseRowModel} from "../purchase/pivot/purchaseRow/PurchaseRowTypes";
import { AssessmentModel, AssessmentStatus } from '../assessment/types/AssessmentTypes';
import {ProfitLossModel} from "../profitLoss/ProfitLossTypes";
import {SoftDeleteModel} from "../../lib/samfe/types/ModelTypes";
import {WithCount} from "../generic/ModelTypeExtensions";
import {FileModel} from "../document/FileTypes";
import {ProductModel} from "../product/ProductTypes";
import {ProductRiskModel} from "../product/pivot/productRisk/ProductRiskTypes";
import {ChargeLocationModel} from "../location/ChargeLocationTypes";

export type ChargeRelationsBluePrint = (
    'chargeLocations'
    |'chargeLocations.location'
    |'chargeLocations.location.group'
    |'blockedRisks'
    |'article'
    |'article.product'
    |'article.product.name'
    |'article.product.productType'
    |'assessments'
    |'assessments.frequencyIndex'
    |'assessments.productRisk'
    |'assessments.productRisk.risk'
    |'assessments.productRisk.product'
    |'assessments.productRisk.element'
    |'assessments.productRisk.element.compound'
    |'assessments.productRisk.element.excipient'
    |'customers'
    |'producer'
    |'repackOrders'
    |'repackOrders.charge'
    |'repackOrders.charge.article'
    |'repackOrders.charge.article.product'
    |'repackOrders.charge.article.product.productType'
    |'repackOrders.article'
    |'repackOrders.parent'
    |'repackOrders.parent.article'
    |'repackOrders.parent.article.product'
    |'repackOrders.parent.article.product.productType'
    |'stock'
    |'parent'
    |'parent.purchaseRow'
    |'parent.purchaseRow.purchase'
    |'parent.purchaseRow.purchase.producer'
    |'purchaseRow'
    |'purchaseRow.purchase'
    |'purchaseRow.purchase.producer'
    |'saleRows'
    |'saleRows.sale'
    |'saleRows.sale.customer'
    |'product'
    |'product.productVersionLogs'
    |'product.productRisks'
    |'product.productRisks.risk'
    |'profitLosses'
    |'files'
    |'fromRepackOrders'
    |'fromRepackOrders.parentCharge'
    |'samfeStock'
    |'assessmentFrequency'
    |'article.product.productRisks.risk'
    |'article.product.compositionProducts'
    |'article.product.compositionProducts.element'
    |'article.product.compositionProducts.element.elementRisks'
    |'article.product.compositionProducts.element.compound'
    |'article.product.compositionProducts.element.excipient'
    );


export type ChargeStatus = 'processed'|'blocked'
export const ChargeStatuses: ChargeStatus[] = ['processed', 'blocked'];
export const ChargeStatusDisplayName = (status?: ChargeStatus): string => {
    switch (status) {
        case "processed": return 'Verwerkt'
        case "blocked": return 'Geblokkeerd'
        default: return 'Onbekend'
    }
}


export type AssessmentFrequencyType = 'self'|'parent'|'inherit'|'missing'|'not_applicable';
export type AssessmentFrequency = {
    type: AssessmentFrequencyType
    test_overdue: boolean
    manual_created: boolean

    risk_name: string
    product_risk_id: number
    frequency: number
    frequency_index: number
    elements: string[]
    assessment_id: number
    charge_id: number
    batchcode: number
    status: AssessmentStatus,
    files: FileModel[],
    requested_at: string|null
    approved_at: string|null
    assessment_file_item?: {
        charge?: ChargeModel
        files?: FileModel[]
    }
}

export type ChargeFields = {
    article_id?: number
    received_date?: string
    expiration_date?: string
    product_id?: number
    packaging_slip ?: string
    batchcode?: string
    amount?: number
    comments?: string
    pack_slip_note?: string
    parent_id?: number,
    favourite?: boolean,
    price_per_amount?: number,
    status?: ChargeStatus
}

export type ChargeDto = Partial<ChargeFields>

export type ChargeRelations = {
    chargeLocations?: ChargeLocationModel[]
    product?: ProductModel,
    article?: ArticleModel
    customers?: CustomerModel[]
    producer?: ProducerModel,
    purchaseRow?: PurchaseRowModel,
    parent?: ChargeModel,
    sales?: SaleModel[]
    saleRows?: SaleRowModel[],
    repackOrders?: RepackingModel[],
    stock?: StockChargeModel
    assessments?: AssessmentModel[],
    profitLosses?: ProfitLossModel[],
    files: FileModel[],
    fromRepackOrders: RepackingModel[],
    toRepackOrders: RepackingModel[],
    samfeStock: SamfeStockItem,
    assessmentFrequency: AssessmentFrequency[]
    blockedRisks: ProductRiskModel[]
}

export type ChargeModel = SoftDeleteModel & Partial<ChargeRelations> & WithCount<Partial<ChargeRelations>> & ChargeDto
