import React, { FC, useMemo } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { batchCodeLabel, expirationDateLabel } from '../../../charge/ChargeFunctions';
import { ChargeModel, ChargeRelationsBluePrint, ChargeStatusDisplayName } from '../../../charge/ChargeTypes';
import PurchaseRowForm from '../../../purchase/pivot/purchaseRow/PurchaseRowForm';
import { PurchaseRowModel, PurchaseRowRelationsBluePrint } from '../../../purchase/pivot/purchaseRow/PurchaseRowTypes';
import { usePurchaseRowResource } from '../../../purchase/pivot/purchaseRow/usePurchaseRow';
import useCustomerCharge from './useCustomerCharge';


type Props = Omit<Required<ExtendTableProps>, 'notification'|'htmlBefore'>

const CustomerChargeTable: FC<Props> = ({parentId}): JSX.Element => {

    const httpHook = useCustomerCharge(parentId);


    const rows = useMemo((): TableColumns<ChargeModel, ChargeRelationsBluePrint>[] => [
        {
            header: {
                children: 'Artikelnummer',
            },
            column: (item) => ({
                children: item.article?.number
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Naam',
            },
            column: (item) => ({
                children: item.product?.name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Charge',
                sortCol: 'batchcode'
            },
            column: (item) => ({
                children: batchCodeLabel(item)
            }),
            type: 'element'
        },
        {
            header: {
                children: 'THT',
                sortCol: 'expiration_date'
            },
            column: (item) => ({
                children: expirationDateLabel(item.expiration_date, true)
            }),
            type: 'element'
        }
    ], []);

    return <>
        <Table
            id={ 'charges' }
            rows={ rows }
            rowActions={ {
                linkTo: (item) => `/charges/${ item.id }`
            } }
            http={ {
                hook: httpHook,
                searchCols: ['batchcode','article.number', 'product.name'],
                with: ['product', 'article'],
                sortCol: 'expiration_date',
                sortDir: 'DESC',
            } }
        />
    </>;
};
export default CustomerChargeTable;