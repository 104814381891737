import React, { FC, useMemo } from 'react';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { DefaultTableFilter, TableFilterType } from '../../components/table/types/TableFilter';
import { TableColumns } from '../../components/table/types/TableRow';
import { thousandSeparator } from '../../lib/samfe/modules/Parse/Number';
import ToOrderForm from '../toOrder/ToOrderForm';
import ArticleForm from './ArticleForm';
import { ArticleModel, ArticleRelationsBluePrint } from './ArticleTypes';
import useArticle from './useArticle';


type Props = Omit<ExtendTableProps, 'parentId'|'notification'>&{
    productId?: number,
    packageId?: number
}

const BaseArticleTable: FC<Props> = ({ productId, packageId }) => {

    const httpHook = useArticle();

    const rows = useMemo((): TableColumns<ArticleModel, ArticleRelationsBluePrint>[] => {
        const rows: TableColumns<ArticleModel, ArticleRelationsBluePrint>[] = [
            {
                header: {
                    children: 'Artikelnummer',
                    sortCol: 'number'
                },
                column: (item) => ({
                    children: item.number,
                    linkTo: (item) => `/articles/${ item.id }`
                }),
                type: 'id'
            }
        ];

        if (!productId) {
            rows.push({
                header: {
                    children: 'Naam'
                },
                column: (item) => ({
                    children: item.product?.name,
                    linkTo: (item) => `/products/${ item.product?.id }`
                }),
                type: 'text'
            });
        }
        if (!packageId) {
            rows.push({
                header: {
                    children: 'Verpakking'
                },
                column: (item) => ({
                    children: item.package?.name
                }),
                type: 'text'
            });
        }

        rows.push(
            {
                header: {
                    children: 'Inhoud',
                    sortCol: 'amount'
                },
                column: (item) => {
                    const amount = (item?.amount ?? 0);
                    const isPlural = amount != 1;
                    let unit = (item?.product?.productType?.[isPlural ?'plural' :'name'] ?? 'onbekend').toLowerCase();
                    if (unit === 'onbekend') {
                        unit = isPlural ?'stuks' :'stuk';
                    }

                    return {
                        children: `${ thousandSeparator(amount) } ${ unit }`
                    };
                },
                type: 'text'
            },
            {
              header: {
                  children: 'Beschikbaar'
              },
              column: (item) => ({
                  children: (item.samfeStock?.usable_stock??0) + (item.samfeStock?.open_net_repacking??0)
              }),
              type: 'numeric'
            },
            {
                header: {
                    children: 'Rek voorraad'
                },
                column: (item) => ({
                    children: item.samfeStock?.rack
                }),
                type: 'numeric'
            },
            {
                header: {
                    children: 'Te versturen'
                },
                column: (item) => ({
                    children: item.samfeStock?.open_sales
                }),
                type: 'numeric'
            },
            {
                header: {
                    children: 'Uitvul opdrachten'
                },
                column: (item) => ({
                    children: item.samfeStock?.open_net_repacking
                }),
                type: 'numeric'
            },
            {
                header: {
                    children: 'In bestelling bij leverancier'
                },
                column: (item) => ({
                    children: item.samfeStock?.open_purchases
                }),
                type: 'numeric'
            },
            {
                header: {
                    children: 'Opnemen in te bestellen'
                },
                column: (item) => ({
                    children: item.active && !item.exclude_to_order ?'Ja️' :'Nee'
                }),
                type: 'text'
            },
            {
                header: {
                    children: 'Status',
                    sortCol: 'active'
                },
                column: (item) => ({
                    children: item.active ?'Actief' :'Inactief'
                }),
                type: 'text'
            }
        );
        return rows;
    }, [ productId, packageId ]);

    const filter = useMemo(() => {
        if (productId != undefined) {
            return {
                column: 'product_id',
                operator: '=',
                value: productId
            } as DefaultTableFilter<ArticleModel>;
        }

        if (packageId != undefined) {
            return {
                column: 'package_id',
                operator: '=',
                value: packageId
            } as DefaultTableFilter<ArticleModel>;
        }
        return undefined;
    }, [ productId, packageId ]);

    const filters: TableFilterType<ArticleModel>[] = useMemo(() => {
        const tableFilters: TableFilterType<ArticleModel>[] = [];

        if (!packageId) {
            tableFilters.push(
                {
                    column: 'is_bulk',
                    displayName: 'Bulk',
                    value: 1
                },
                {
                    column: 'is_bulk',
                    displayName: 'Verpakking',
                    value: 0
                }
            );
        }

        tableFilters.push(
            {
                column: 'active',
                displayName: 'Actief',
                value: 1,
                default: !productId || !packageId
            },
            {
                column: 'active',
                displayName: 'Inactief',
                value: 0
            });
        return tableFilters;
    }, [ packageId, productId ]);

    return (
        <Table
            id={ 'articles' }
            rows={ rows }
            rowDisabled={ (item) => ( item.active == 0 ?? 0 ) }
            http={ {
                hook: httpHook,
                searchCols: [ 'number', 'product.name' ],
                with: [
                    'product.productType', 'package', 'samfeStock', 'totalSalesAfter2012', 'totalSalesAfter2022', 'product.productType'
                ],
                sortCol: 'number',
                sortDir: 'ASC',
                filter,
                filters
            } }
            forms={ {
                edit: {
                    Form: ArticleForm,
                    id: (record) => record.id
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.number }`,
                    resourceName: () => 'Artikel'
                },
                actions: [
                    {
                        title: 'Bestellen',
                        disableForRow: (article) => article.exclude_to_order || article.active === false,
                        Form: ToOrderForm,
                        id: (item) => item.id
                    }
                ]
            } }
        />
    );
};

export default BaseArticleTable;