import React, { FC, useMemo } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/table';
import { ExtendTableProps, TableNotification } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import useAsyncMemo from '../../lib/samfe/hooks/useAsyncMemo';
import { ucFirst } from '../../lib/samfe/modules/Parse/String';
import SaleForm from './SaleForm';
import SaleSentModal from './SaleSentModal';
import { SaleModel, SaleRelationsBluePrint, SaleStatuses, SaleStatusTranslation } from './SaleTypes';
import useSale from './useSale';


const emptySalesLimit = 20;

type Props = Required<ExtendTableProps>

const SaleTable: FC<Props> = (): JSX.Element => {

    const httpHook = useSale();


    const openSale = useSale();

    const emptySales: SaleModel[] = useAsyncMemo(async() => {
        return await openSale.getList({
            limit: emptySalesLimit,
            select: [ 'id' ],
            orderBy: 'id',
            order: 'DESC',
            doesntHave: [ 'saleRows' ],
            filter: 'status!=sent,status!=canceled'
        });
    }, [], []);


    const emptySaleCount = useMemo(() => {
        return openSale.pagination.pagination.totalItems ?? 0;
    }, [ openSale.pagination.pagination.totalItems ]);


    const notification: TableNotification|undefined = useMemo(() => {

        if (emptySales.length == 0) {
            return undefined;
        }

        const nEmpty = emptySalesLimit>emptySaleCount ?emptySaleCount :emptySalesLimit;
        return {
            title: `Openstaande orders (laatste ${ nEmpty } v/d ${ emptySaleCount } open orders)`,
            variation: 'warning',
            children: <ul>
                { emptySales.map(sale => <li key={ sale.id }>
                    Order { sale.id } heeft nog geen orderregels,&nbsp;
                    <Link className={ 'underline' } to={ `/sales/${ sale.id }` }>bekijken</Link>.
                </li>) }
            </ul>
        } as TableNotification;

    }, [ emptySaleCount, emptySales ]);


    const rows = useMemo((): TableColumns<SaleModel, SaleRelationsBluePrint>[] => [
        {
            header: {
                children: 'Pakbonnummer',
                sortCol: 'id'
            },
            column: (item) => ({
                children: item.id,
                linkTo: (item) => `/sales/${ item.id }`
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Klant'
            },
            column: (item) => {
                let recipient = item.customer?.company;
                if (!recipient) {
                    const firstName = ucFirst(item.customer?.first_name);
                    const lastName = ucFirst(item.customer?.last_name);
                    const spacer = firstName.length>0 && lastName.length>0 ?' ' :'';
                    recipient = `${ firstName }${ spacer }${ lastName }`;
                }
                const customerNumber = item.customer?.number ?? 0
                return {
                    children: `${recipient} (${customerNumber})`,
                    linkTo: (item) => `/customers/${ item.customer?.id }`
                };
            },
            type: 'text'
        },
        {
            header: {
                children: 'Besteldatum',
                sortCol: 'ordered_at'
            },
            column: (item) => ({
                children: item.ordered_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Verzenddatum',
                sortCol: 'shipped_at'
            },
            column: (item) => ({
                children: item.shipped_at
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Aantal orderregels',
            },
            column: (item) => ({
                children: item.saleRows_count
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Inkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.purchase_price
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Verkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.total
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Winstmarge'
            },
            column: (item) => ({
                children: item.financialData?.profit_margin_percentage
            }),
            type: 'percentage'
        },
        {
            header: {
                children: 'Status'
            },
            column: (item) => ({
                children: SaleStatusTranslation(item?.status)
            }),
            type: 'text'
        }
    ], []);

    return <Table
        id={ 'sales' }
        rows={ rows }
        notification={ notification }
        // rowActions={ {
        //     linkTo: (item) => `${ item.id }`
        // } }
        http={ {
            hook: httpHook,
            sortCol: 'id',
            sortDir: 'DESC',
            searchCols: [ 'id', 'customer.company', 'wcImport.wc_order_id' ],
            with: [ 'customer', 'wcImport', 'saleRows', 'financialData' ],
            withCount: [ 'saleRows' ],
            filters: SaleStatuses.map(status => (
                {
                    column: 'status',
                    displayName: SaleStatusTranslation(status),
                    value: status
                }
            )),
            select: [
                'id',
                'ordered_at',
                'shipped_at',
                'status',
                'customer_id',
                'saleRows.id',
                'saleRows.sale_id',
                'saleRows.status',
                'customer.id',
                'customer.first_name',
                'customer.last_name',
                'customer.company',
                'customer.number'
            ]
        } }
        forms={ {
            edit: {
                Form: SaleForm,
                id: (record) => record.id,
                disableForRow: (item) => item.status == 'sent'
            },
            archive: {
                id: (record) => record.id,
                itemName: (record) => `${ record.id }`,
                resourceName: () => 'Verkooporder',
                disableForRow: (item) => item.status == 'sent'
            },
            actions: [
                {
                    title: 'Verzenden',
                    id: (item) => item.id,
                    Form: SaleSentModal,
                    disableForRow: (currentSale) => {
                        const saleRows = currentSale.saleRows ?? [];
                        if ((currentSale.saleRows_count ?? 0) == 0) {
                            return false;
                        }
                        // can only send if at least one row is open
                        return saleRows.find(sr => sr.status == 'to send') == undefined;
                    }
                }
            ]
        } }
    />;
};

export default SaleTable;