import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import Table from '../../components/table';
import { ExtendTableProps } from '../../components/table/types/Table';
import { TableColumns } from '../../components/table/types/TableRow';
import Input from '../../deprecated/components/Input/Input';
import TopButton from '../../lib/samfe/components/Button/TopButton';
import { jsDateToSqlDate } from '../../lib/samfe/modules/Parse/Date';
import { ArticleModel, ArticleRelationsBluePrint } from '../article/ArticleTypes';
import useArticle from '../article/useArticle';
import useFileHandler from '../document/useFileHandler';


type Props = Required<ExtendTableProps>

const StockTable: FC<Props> = () => {

    const httpHook = useArticle();
    const [ currentDate, setCurrentDate ] = useState(jsDateToSqlDate());
    const { getDocument } = useFileHandler();

    const rows = useMemo((): TableColumns<ArticleModel, ArticleRelationsBluePrint>[] => [
        {
            header: {
                children: 'Artikelnummer',
                sortCol: 'number'
            },
            column: (item) => ({
                children: item.number,
                linkTo: (item) => `/articles/${ item.id }`
            }),
            type: 'id'
        },
        {
            header: {
                children: 'Naam',
            },
            column: (item) => ({
                children: item.product?.name ?? '[onbekend product]',
                linkTo: (item) => `/products/${ item.product?.id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Laatste inkoop'
            },
            column: (item) => ({
                children: item.samfeStock?.last_purchase
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Laatste verkoop'
            },
            column: (item) => ({
                children: item.samfeStock?.last_sale
            }),
            type: 'date'
        },
        {
            header: {
                children: 'Rekvoorraad'
            },
            column: (item) => ({
                children: item.samfeStock?.rack
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Bruikbaar'
            },
            column: (item) => ({
                children: item.samfeStock?.usable_stock
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'In bestelling'
            },
            column: (item) => ({
                children: item.samfeStock?.open_purchases
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Levertijd'
            },
            column: (item) => ({
                children: item.delivery_time ?`${ item.delivery_time } weken` :'-'
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Waarde'
            },
            column: (item) => ({
                children: item.samfeStock?.rack_value
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Prijs per stuk'
            },
            column: (item) => ({
                children: item.samfeStock?.rack_value
            }),
            type: 'currency'
        }
    ], []);

    return (<>
            <div className={ 'relative' }>
                <div className={ 'absolute -top-12 right-0' }>
                    <div className={ 'inline-block w-[10rem] mr-4' }>
                        <Input
                            value={ jsDateToSqlDate() }
                            noWrap={ true }
                            name={ '' }
                            type={ 'date' }
                            onChange={ (_, v) => setCurrentDate(v) }
                        />
                    </div>

                    <TopButton
                        text={ 'Exporteren' }
                        icon={ '' }
                        className={ '' }
                        onClick={ () => {
                            getDocument(currentDate, 'stock-csv', [
                                `date=${ currentDate }`
                            ]);
                        } }
                    />
                </div>

                <Table
                    id={ 'stock' }
                    rows={ rows }
                    http={ {
                        hook: httpHook,
                        searchCols: [ 'number', 'product.name' ],
                        with: [ 'product', 'samfeStock' ],
                        filterConfig: {
                            hideArchived: true
                        },
                        disableDeArchiving: true,
                        filter: {
                            column: 'active',
                            value: 1
                        }
                    } }
                />
            </div>
        </>
    );
}; // @todo: disable navigate

export default StockTable;
