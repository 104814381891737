import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Jumbotron from '../../../../lib/samfe/components/Jumbotron/Jumbotron';
import FormModal, {ExtendFormModalProps} from "../../../../lib/samfe/components/Modal/FormModal/FormModal";
import useProductVersionLog, {VersionLogDto, VersionLogModel, VersionLogRelationsBluePrint} from "./VersionLogTypes";
import useSchema, {Shape} from "../../../../lib/samfe/components/Form/Effects/useSchema";
import Yup from "../../../../lib/samfe/components/Form/Yup";
import {useForm} from "../../../../lib/samfe/components/Form";
import useAsyncInit from "../../../../lib/samfe/components/Form/Effects/useAsyncInit";


const impactToText = (impact: string): string => {
    switch (impact) {
        case 'medium':
            return 'Gemiddelde aanpassing'
        case 'high':
            return 'Grote aanpassing'
        case 'low':
        default:
            return 'Kleine aanpassing'
    }
}
const VersionLogForm: FC<ExtendFormModalProps<VersionLogDto>> = ({
    id,
    open,
    setOpen,
    onSuccess,
    parentId
}): JSX.Element => {

    const navigate = useNavigate();
    const versionLog = useProductVersionLog();

    const [ currentVersion, setCurrentVersion ] = useState<VersionLogModel>();



    /**
     *
     */
    const shape = (): Shape<VersionLogDto> => ({
        product_id: Yup.number()
            .defaultValue(currentVersion?.product_id ?? parentId)
            .required()
            .hidden(true),

        impact: Yup.string()
            .required()
            .disabled(currentVersion !== undefined)
            .label('Grootte van verandering')
            .controlType('select')
            .options(['low', 'medium', 'high'].map((impact) => ({
                displayName: impactToText(impact),
                value: impact,
                selected: impact === (currentVersion?.impact ?? 'low')
            }))),

        internal_comment: Yup.string()
            .required()
            .label('Interne notitie')
            .controlType('textArea')
            .defaultValue(currentVersion?.internal_comment ?? '')
            .inputType('text'),

        external_comment: Yup.string()
            .required()
            .label('Externe notitie')
            .controlType('textArea')
            .defaultValue(currentVersion?.external_comment ?? '')
            .inputType('text'),
    });


    /**
     *
     */
    const {setShape, validationSchema} = useSchema<VersionLogDto>(shape());

    const initializer = async () => {
        await versionLog.getItem(id, {with: ['product']}).then(setCurrentVersion);
        setShape(shape());
    };

    useEffect(() => {
        setShape(shape());
    }, [currentVersion])

    /**
     *
     */
    const {formikConfig, formFields} = useForm<VersionLogModel, VersionLogDto, VersionLogRelationsBluePrint>({
        id,
        validationSchema,
        useHttpHook: useProductVersionLog,
        onSuccess: (modelId) => {
            if (modelId && !id) {
                navigate(`/products/${modelId}?current-tab=version-log`, {replace: false})
            }
            onSuccess?.(modelId)
        },
        initializer,
        initialized: useAsyncInit(initializer, open)
    });


    return <FormModal
        id={id}
        htmlBeforeForm={<>
            {currentVersion && <Jumbotron title={`Versie ${currentVersion.product?.version}`} />}
        </>}
        resource={'Productversie'}
        open={open}
        setOpen={setOpen}
        formikConfig={formikConfig}
        formFields={formFields}
    />;
};

export default VersionLogForm;
