import React, { FC, useMemo, useState } from 'react';
import Table from '../../../../components/table';
import { ExtendTableProps } from '../../../../components/table/types/Table';
import { TableColumns } from '../../../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../../../events/Http.event';
import { currentSqlDate } from '../../../../lib/samfe/modules/Parse/Date';
import { batchCodeLabel } from '../../../charge/ChargeFunctions';
import { BlockedChargeModal } from '../../BlockedChargeModal';
import BookInForm from '../bookIn/BookInForm';
import PurchaseRowForm from './PurchaseRowForm';
import { PurchaseRowDto, PurchaseRowModel, PurchaseRowRelationsBluePrint, PurchaseRowStatusDisplayName } from './PurchaseRowTypes';
import usePurchaseRow from './usePurchaseRow';


type Props = Omit<ExtendTableProps, 'parentId'>&{
    parentId: number
}

const PurchaseRowTable: FC<Props> = ({ parentId, htmlBefore, notification }): JSX.Element => {

    const httpHook = usePurchaseRow(parentId);
    const purchaseRowHook = usePurchaseRow();

    const [ bookedChargeId, setBookedChargeId ] = useState<number>();
    const [ showBlockedChargeModal, setShowBlockedChargeModal ] = useState(false);

    const rows = useMemo((): TableColumns<PurchaseRowModel, PurchaseRowRelationsBluePrint>[] => [
        {
            header: {
                children: 'Artikelnummer'
            },
            column: (item) => ({
                children: item.article?.number,
                linkTo: (item) => `/articles/${ item.charge?.article_id ?? item.article_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Naam'
            },
            column: (item) => ({
                children: item.article?.product?.name,
                linkTo: (item) => `/products/${ item.charge?.product_id ?? item.article?.product_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Artikel producent nummer'
            },
            column: (item) => ({
                children: item.articleProducer?.custom_name
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Batchcode'
            },
            column: (item) => ({
                children: batchCodeLabel(item.charge),
                linkTo: !item.charge_id ?undefined :(item) => `/charges/${ item.charge_id }`
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Aantal besteld'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Aantal ingeboekt',
                sortCol: 'booked_quantity'
            },
            column: (item) => ({
                children: item.booked_quantity
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'E.T.A. in weken'
            },
            column: (item) => ({
                children: item.eta
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Overkomstduur in weken'
            },
            column: (item) => ({
                children: item.delivery_time
            }),
            type: 'numeric'
        },
        {
            header: {
                children: 'Prijs p/s',
                sortCol: 'price_per_amount'
            },
            column: (item) => ({
                children: item.price_per_amount
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Regel totaal'
            },
            column: (item) => ({
                // @todo make function for all price per amounts
                children: (item?.price_per_amount ?? 0) * ((item?.quantity ?? 0) / (!item.article?.is_bulk ?1 :1000))
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: PurchaseRowStatusDisplayName(item.status)
            }),
            type: 'text'
        }
    ], []);

    return <>
        <Table
            id={ 'purchase-rows' }
            htmlBefore={ htmlBefore }
            notification={ notification }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: [],
                with: [ 'charge', 'article.product', 'charge.samfeStock', 'eta', 'articleProducer' ],
                sortCol: 'id',
                sortDir: 'DESC',
                filters: []
            } }
            forms={ {
                edit: {
                    Form: PurchaseRowForm,
                    parentId: (record) => record.purchase_id,
                    id: (record) => record.id,
                    disableForRow: (item) => ![ 'open', 'sent', 'received' ].includes(item.status ?? '')
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.id }`,
                    resourceName: () => 'Inkoopregel',
                    disableForRow: (item) => ![ 'open', 'sent', 'received' ].includes(item.status ?? '')
                },
                actions: [
                    {
                        title: 'Inboeken',
                        id: (item) => item.id,
                        parentId: (item) => item.purchase_id,
                        Form: BookInForm,
                        hideForRow: (item) => item.status != 'received',
                        onSuccess: (id) => {
                            setBookedChargeId(id);
                            setShowBlockedChargeModal(true);
                        }
                    }
                ]
            } }
            callbacks={ [
                {
                    title: 'Markeren als ontvangen',
                    hideForRow: (item) => item.status != 'sent',
                    onClick: async(currentItem) => {
                        await purchaseRowHook.updateWithParentId(
                            currentItem?.purchase_id!,
                            currentItem?.id!,
                            {
                                status: 'received',
                                received_at: currentSqlDate()
                            }
                        ).then(dispatchHttpEvent);
                    }
                },
                {
                    title: 'Dupliceren',
                    onClick: async(currentItem) => {
                        const payload: PurchaseRowDto = {
                            article_id: currentItem.article_id!,
                            purchase_id: currentItem.purchase_id!,
                            price_per_amount: parseFloat(`${ currentItem.price_per_amount }`)!,
                            status: currentItem.status !== 'booked' ?currentItem.status! :'sent',
                            quantity: 0
                        };

                        if (currentItem.status !== 'open') {
                            payload.sent_at = currentItem.sent_at;
                        }

                        if (currentItem.status == 'received') {
                            payload.received_at = currentItem.received_at;
                        }

                        if (currentItem.comments !== undefined && currentItem.comments != '') {
                            payload.comments = currentItem.comments;
                        }

                        await purchaseRowHook.createWithParentId(
                            currentItem?.purchase_id!,
                            payload
                        ).then(dispatchHttpEvent);
                    }
                }
            ] }
        />
        { showBlockedChargeModal && bookedChargeId && <BlockedChargeModal
            open={ showBlockedChargeModal }
            setOpen={ setShowBlockedChargeModal }
            chargeId={ bookedChargeId }
            onClose={ () => {
                setBookedChargeId(undefined);
            } }

        /> }
    </>;
};
export default PurchaseRowTable;