import React, { FC, ReactNode, useCallback, useMemo } from 'react';
import { classNames } from '../../modules/Parse/String';
import Skeleton from '../Skeleton/Skeleton';


export type Stat = {
    name: string,
    value?: ReactNode,
    change?: string,
    changeType?: 'positive'|'negative'
}

type Props = {
    stats: Stat[]
}

const Stats: FC<Props> = ({ stats }): JSX.Element => {

    const remainderFields = useMemo(() => {
        const remainder = 4 - stats.length % 4;
        return remainder<4 ?remainder :0;
    }, [ stats.length ]);

    const itemWrapper = useCallback((stat: Stat|undefined, index: number, isPlaceHolder: boolean = false) => {
        return <>
            <tr>
              <td className={ classNames( 'text-sm font-medium leading-6 text-aqua' )}>
                { stat?.name }
              </td>
              <td className={ classNames( 'text-sm font-medium leading-6 text-aqua' )}>
                { stat?.change }
                { stat?.value ?? (!isPlaceHolder ?<Skeleton type={ 'stat.body' }/> :'') }
              </td>
            </tr>
            </>
    }, []);

    return (
        <div className={ 'mx-4 sm:mx-10 lg:mx-1 lg:my-10 border-b border-gray-200' }>
            <div className=" md:mx-2 lg:mx-0 lg:my-10">
                <table className={ classNames( "w-full" ) }>
                  <thead>
                    <tr>
                      <th className={ classNames( "w-2/12 text-left" ) }>Eigenschap</th>
                      <th className={ classNames( "text-left" ) }>Waarde</th>
                    </tr>
                  </thead>
                  <tbody>
                    { stats.map((stat, i) => itemWrapper(stat, i)) }
                    { [ ...Array(remainderFields) ].map((_, i) => itemWrapper(undefined, i, true)) }
                  </tbody>
                </table>
            </div>
        </div>
    );
};
// const Stats: FC<Props> = ({ stats }): JSX.Element => {

//     const remainderFields = useMemo(() => {
//         const remainder = 4 - stats.length % 4;
//         return remainder<4 ?remainder :0;
//     }, [ stats.length ]);

//     const itemWrapper = useCallback((stat: Stat|undefined, index: number, isPlaceHolder: boolean = false) => {
//         return <div key={ index } className={ classNames(isPlaceHolder && 'hidden xl:block', 'border border-gray-200 -mt-px gap-x-4 gap-y-2 bg-white py-4 px-10 md:px-8 lg:px-12') }>
//             <dt className="text-sm font-medium leading-6 text-aqua pb-2">{ stat?.name }</dt>
//             <dd className={ classNames(stat?.changeType === 'negative' ?'text-fire' :'text-graphite', 'text-xs font-medium') }>
//                 { stat?.change }
//             </dd>
//             <dd className="w-full flex-none text-lg font-medium leading-4 tracking-tight text-graphite pb-2 items-start">
//                 { stat?.value ?? (!isPlaceHolder ?<Skeleton type={ 'stat.body' }/> :'') }
//             </dd>
//         </div>;
//     }, []);

//     return (
//         <div className={ '-mx-10 md:-mx-12 md:-mx-15 lg:-mx-[calc(3rem-1px)] border-b border-gray-200' }>
//             <div className=" md:mx-2 lg:mx-0">
//                 <dl className="mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 -mb-px -ml-px">
//                     { stats.map((stat, i) => itemWrapper(stat, i)) }
//                     { [ ...Array(remainderFields) ].map((_, i) => itemWrapper(undefined, i, true)) }
//                 </dl>
//             </div>
//         </div>
//     );
// };
export default Stats;