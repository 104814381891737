import React from 'react';
import { useForm } from '../../lib/samfe/components/Form';
import useAsyncInit from '../../lib/samfe/components/Form/Effects/useAsyncInit';
import useSchema, { Shape } from '../../lib/samfe/components/Form/Effects/useSchema';
import { SelectOption } from '../../lib/samfe/components/Form/Effects/useSelect';
import { optionIsSelected } from '../../lib/samfe/components/Form/Support/FieldSupport';
import Yup from '../../lib/samfe/components/Form/Yup';
import { FormModal } from '../../lib/samfe/components/Modal';
import { ExtendFormModalProps } from '../../lib/samfe/components/Modal/FormModal/FormModal';
import { getProductVersionDisplayNameByProduct } from '../product/ProductFunctions';
import { ChargeDto, ChargeModel, ChargeRelationsBluePrint, ChargeStatusDisplayName, ChargeStatuses } from './ChargeTypes';
import useCharge from './useCharge';
import useProduct from "../product/useProduct";


const ChargeForm: React.FC<ExtendFormModalProps<ChargeDto>> = ({ id, open, setOpen, onSuccess }): JSX.Element => {

    const charge = useCharge();

    const shape = (initialCharge?: ChargeModel): Shape<ChargeDto> => ({

        batchcode: Yup.string()
            .label('Charge')
            .required()
            .controlType('input')
            .inputType('text'),

        article_id: Yup.number()
            .hidden(true)
            .label('Artikel')
            .controlType('input')
            .inputType('number'),

        product_id: Yup.number()
            .hidden(initialCharge === undefined)
            .label('Productversie')
            .controlType('selectSearch')
            .selectSearchConfig({
                initialModel: initialCharge?.product,
                expectsInitialModel: initialCharge?.product != undefined,
                useHook: useProduct,
                searchOptions: {
                    searchCols: ["name"],
                    relations: ['productVersionLogs'],
                    valueCol: "id",
                    filter: initialCharge?.product ? `number=${initialCharge?.product.number}` : 'id=0',
                    displayName: model => getProductVersionDisplayNameByProduct(model)
                }
            }),

        amount: Yup.number()
            .hidden(true)
            .label('Aantal')
            .controlType('input')
            .inputType('number'),

        price_per_amount: Yup.number()
            .label(`Prijs ${ initialCharge?.article?.is_bulk ?'per 1000 stuks' :'' }`)
            .description('Per 1000 voor bulk')
            .controlType('input')
            .inputType('number'),

        parent_id: Yup.number()
            .hidden(true)
            .label('Parent charge')
            .controlType('input')
            .inputType('number'),

        // ChargeStatuses
        status: Yup.string()
            .label('Status')
            .required()
            .controlType('select')
            .options(ChargeStatuses.map((status, i) => ({
                displayName: ChargeStatusDisplayName(status),
                value: status,
                selected: optionIsSelected(initialCharge?.status, status, i)
            }) as SelectOption)),

        received_date: Yup.string()
            .label('Ontvangstdatum')
            .required()
            .controlType('input')
            .inputType('date'),

        expiration_date: Yup.string()
            .label('THT')
            .required()
            .controlType('input')
            .inputType('date'),

        packaging_slip: Yup.string()
            .label('Pakbon nummer')
            .required()
            .controlType('input')
            .inputType('text'),

        comments: Yup.string()
            .label('Notitie')
            .controlType('textArea'),

        pack_slip_note: Yup.string()
            .label('Pakbon notitie')
            .description('Voor Remco')
            .controlType('textArea'),

        favourite: Yup.boolean()
            .label('Markeren als favoriet')
            .controlType('checkbox')
            .defaultValue(initialCharge?.favourite === true)
    });

    const { validationSchema, setShape } = useSchema<ChargeDto>(shape());

    const initializer = async() => {

        const initialCharge = await charge.getItem(id, { with: [ 'article', 'product.productVersionLogs' ] });
        setShape(shape(initialCharge));
    };

    const { formikConfig, formFields } = useForm<ChargeModel, ChargeDto, ChargeRelationsBluePrint>({
        id: id,
        validationSchema: validationSchema,
        useHttpHook: useCharge,
        onSuccess,
        initializer,
        initialized: useAsyncInit(initializer, open),
        morphPayload: (formikValues, dto) => {
            const newDto = {...dto};
            if(id !== undefined && `${formikValues['pack_slip_note'] ?? ''}`.replaceAll(' ', '') === '') {
                //@ts-ignore
                newDto.pack_slip_note = null;
            }
            return newDto;
        }
    });

    return <FormModal
        id={ id }
        resource={ 'Charge' }
        open={ open }
        setOpen={ setOpen }
        formikConfig={ formikConfig }
        formFields={ formFields }
    />;
};

export default ChargeForm;