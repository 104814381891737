import React, { FC, useMemo, useState } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { dispatchHttpEvent } from '../../../events/Http.event';
import { UseHttp } from '../../../lib/samfe/modules/Http/useHttp';
import useFileHandler from '../../document/useFileHandler';
import AssessmentBookForm from '../forms/book/AssessmentBookForm';
import AssessmentRequestForm from '../forms/request/AssessmentRequestForm';
import { assessmentRequested } from '../hooks/useAssessmentIsRequested';
import { GroupedAssessmentHistory } from '../types/AssessmentTypes';
import { AssessmentRiskItemTitle, WithAssessmentTimeStamps } from './components/AssessmentRiskItem';
import AssessmentRiskItemsColumn from './components/AssessmentRiskItemsColumn';
import ChargeBatchCodeColumn from './components/ChargeBatchCodeColumn';
import ChargeExpirationDateColumn from './components/ChargeExpirationDateColumn';
import ChargeOriginColumn from './components/ChargeOriginColumn';
import ChargeProductColumn from './components/ChargeProductColumn';
import ChargeReceivedDateColumn from './components/ChargeReceivedDateColumn';
import { AssessmentTableFilter } from './hooks/useAssessmentTableFilters';


type AssessmentRow = TableColumns<GroupedAssessmentHistory, ''>

type Props = Omit<ExtendTableProps, 'notification'|'parentId'>&WithAssessmentTimeStamps&{
    httpHook: UseHttp<GroupedAssessmentHistory, {}, ''>
    hideProduct?: boolean,
    hideArticle?: boolean
    hideCharge?: boolean,
    filters?: AssessmentTableFilter[]
}

const BaseAssessmentTable: FC<Props> = ({
    httpHook,
    hideProduct = false,
    hideArticle,
    hideCharge = false,
    filters = [],
    htmlBefore,
    withRequestedAt = false,
    witApprovedAt = false
}) => {

    const { getDocument } = useFileHandler();
    const [ openRequestForm, setOpenRequestForm ] = useState(false);
    const [ openBookForm, setOpenBookForm ] = useState(false);
    const [ currentChargeId, setCurrentChargeId ] = useState<number>()
    ;
    const rows = useMemo((): TableColumns<GroupedAssessmentHistory, ''>[] => {

        const rows: AssessmentRow[] = [
            {
                header: {
                    children: !hideArticle ? (hideProduct ?'Artikel' :'Product') : 'Oorsprong'
                },
                column: (item) => ({
                    children: <>
                        {!hideArticle && <ChargeProductColumn
                            hideProduct={ hideProduct }
                            productId={ item.product_id }
                            productName={ item.product_name }
                            articleNumber={ item.article_number }
                        /> }
                        <ChargeOriginColumn
                            producerName={ item.producer_name }
                            purchaseId={ item.purchase_id }
                            repackId={ item.repack_id }
                            parentCharge={ item.assessmentHistory.length>0 ?item.assessmentHistory[0].parent?.charge :undefined }
                            showLarge={hideArticle == true}
                        />
                    </>
                }),
                style: {
                    verticalAlign: 'text-top'
                }
            }
        ];

        if (!hideCharge) {
            rows.push({
                header: {
                    children: 'Charge'
                },
                column: (item) => ({
                    children: <ChargeBatchCodeColumn charge={ item }/>
                }),
                style: {
                    verticalAlign: 'text-top'
                }
            });
        }

        rows.push({
                header: {
                    children: 'Inboekdatum'
                },
                column: (item) => ({
                    children: <ChargeReceivedDateColumn receivedDate={ item.received_date }/>
                }),
                style: {
                    verticalAlign: 'text-top'
                }
            },
            {
                header: {
                    children: 'THT'
                },
                column: (item) => ({
                    children: <ChargeExpirationDateColumn expirationDate={ item.expiration_date }/>
                }),
                style: {
                    verticalAlign: 'text-top'
                }
            },
            {
                header: {
                    children: <>
                        <span className={ 'block !text-left' }>Risico borgingen</span>
                        <AssessmentRiskItemTitle withRequestedAt={ withRequestedAt } witApprovedAt={ witApprovedAt }/>
                    </>
                },
                column: (item) => ({
                    children: <AssessmentRiskItemsColumn
                        items={ item.assessmentHistory }
                        witApprovedAt={ witApprovedAt }
                        withRequestedAt={ withRequestedAt }
                    />
                }),
                style: {
                    verticalAlign: 'text-top'
                }

            }
        );
        return rows;
    }, [ hideProduct, hideCharge, withRequestedAt, witApprovedAt ]);

    return <>
        <Table
            id={ 'Risico borgingen' }
            rows={ rows }
            rowDisabled={ (item) => !!item.repack_id }
            http={ {
                hook: httpHook,
                // @feature implement in backend
                searchCols: ['batchcode'],
                sortCol: 'id',
                sortDir: 'DESC',
                // @ts-ignore BE support this
                filters: filters
            } }
            styling={ {
                variation: 'buttons'
            } }
            callbacks={ [
                {
                    title: 'COA downloaden',
                    onClick: (item) => getDocument(item.id, 'coa')
                },
                {
                    title: 'Analyses aanvragen',
                    onClick: (item) => {
                        setOpenRequestForm(true);
                        setOpenBookForm(false);
                        setCurrentChargeId(item.id);
                    }
                },
                {
                    title: 'Risico\'s borgen',
                    disableForRow: (item) => {
                        const requestedItems = item.assessmentHistory.filter(assessmentRequested);
                        return requestedItems.length == 0;
                    },
                    onClick: (item) => {
                        setOpenRequestForm(false);
                        setOpenBookForm(true);
                        setCurrentChargeId(item.id);
                    }
                }
            ] }
            htmlBefore={htmlBefore}
        />

        { openRequestForm && currentChargeId && <AssessmentRequestForm
            openState={ [ openRequestForm, setOpenRequestForm ] }
            chargeId={ currentChargeId }
            onSave={ () => {
                setCurrentChargeId(undefined);
                dispatchHttpEvent();
            } }
        /> }

        { openBookForm && currentChargeId && <AssessmentBookForm
            openState={ [ openBookForm, setOpenBookForm ] }
            chargeId={ currentChargeId }
            onSave={ () => {
                setCurrentChargeId(undefined);
                dispatchHttpEvent();
            }
            }/> }
    </>;
};

export default BaseAssessmentTable;
