
export const APP_NAME             = process.env.REACT_APP_NAME ?? "Great Applications";
export const APP_URL              = process.env.REACT_APP_APP_URL ?? "http://localhost:3000";
export const API_URL              = process.env.REACT_APP_API_URL ?? "http://localhost";
export const PRINTER_URL          = process.env.REACT_APP_PRINTER_URL ?? "http://192.168.1.56";
export const APP_DOMAIN           = process.env.REACT_APP_DOMAIN ?? ".tpga.samfe.io";
export const COOKIE_PREFIX        = process.env.REACT_APP_COOKIE_PREFIX ?? "ga";
export const PRODUCTION_MODE    = process.env.REACT_APP_PRODUCTION_MODE ? process.env.REACT_APP_PRODUCTION_MODE === 'true' : true;
export const DISABLE_SIDEBAR    = process.env.REACT_APP_DISABLE_SIDEBAR ? process.env.REACT_APP_DISABLE_SIDEBAR === 'true' : false;

