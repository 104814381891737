import { FC } from 'react';
import TableTestIndex from './components/table/test/Table.test.index';
import TableTestShow from './components/table/test/Table.test.show';

// Misc
import CancelToken from './pages/Auth/CancelToken';
import ForgotPassword from './pages/Auth/ForgotPassword';

// Auth
import Login from './pages/Auth/Login';
import Logout from './pages/Auth/Logout';
import ResetPassword from './pages/Auth/ResetPassword';


// Info related
import Dashboard from './pages/Dashboard/Dashboard';

// User
import ProfileShow from './pages/Profile/ProfileShow';
import Settings from './pages/Settings';
import StatusPage from './pages/StatusPage';
import Test from './pages/Test/Test';
import TestIndex from './pages/Test/TestIndex';
import AccountIndex from './resources/account/AccountIndex';

// Article related
import ArticleIndex from './resources/article/ArticleIndex';
import ArticleShow from './resources/article/ArticleShow';
import AssessmentIndex from './resources/assessment/pages/AssessmentIndex';
import AttributeIndex from './resources/attribute/AttributeIndex';
import AttributeShow from './resources/attribute/AttributeShow';
import CategoryIndex from './resources/category/CategoryIndex';
import CategoryShow from './resources/category/CategoryShow';

// Charge related
import ChargeIndex from './resources/charge/ChargeIndex';
import ChargeShow from './resources/charge/ChargeShow';

// Element related
import CompoundIndex from './resources/compound/CompoundIndex';
import CompoundShow from './resources/compound/CompoundShow';

// Sales related
import CustomerIndex from './resources/customer/CustomerIndex';
import CustomerShow from './resources/customer/CustomerShow';
import ElementReviewIndex from './resources/elementReview/ElemenReviewtIndex';
import PrinterJobIndex from './resources/printerJob/PrinterJobIndex';
import PrinterJobShow from './resources/printerJob/PrinterJobShow';
import PrinterIndex from './resources/printer/PrinterIndex';
import PrinterShow from './resources/printer/PrinterShow';
import { ElementCompoundIndex, ElementExcipientIndex } from './resources/element/ElementIndex';
import ElementCompoundShow from './resources/element/pivot/elementCompound/ElementCompoundShow';
import ElementExcipientShow from './resources/element/pivot/elementExcipient/ElementExcipientShow';
import ExcipientIndex from './resources/excipient/ExcipientIndex';
import ExcipientShow from './resources/excipient/ExcipientShow';
import IngredientIndex from './resources/ingredient/IngredientIndex';
import IngredientShow from './resources/ingredient/IngredientShow';
import LocationIndex from './resources/location/LocationIndex';
import LocationShow from './resources/location/LocationShow';
import PackageIndex from './resources/package/PackageIndex';
import PackageShow from './resources/package/PackageShow';
import PackagePartIndex from './resources/packagePart/PackagePartIndex';
import PackagePartShow from './resources/packagePart/PackagePartShow';

// Purchase related
import ProducerIndex from './resources/producer/ProducerIndex';
import ProducerShow from './resources/producer/ProducerShow';
import ToOrderIndex from './resources/toOrder/ToOrderIndex';

// Product related
import ProductsIndex from './resources/product/ProductIndex';
import ProductShow from './resources/product/ProductShow';
import ProductRiskReviewIndex from './resources/productRiskReview/ProductRiskReviewIndex';
import ProductTypeIndex from './resources/productType/ProductTypeIndex';
import ProductTypeShow from './resources/productType/ProductTypeShow';
import PurchaseIndex from './resources/purchase/PurchaseIndex';
import PurchaseShow from './resources/purchase/PurchaseShow';
import RepackingIndex from './resources/repacking/RepackingIndex';

// Risk related
import RiskIndex from './resources/risk/RiskIndex';
import RiskShow from './resources/risk/RiskShow';
import RiskAssuranceIndex from './resources/riskAssurance/RiskAssuranceIndex';
import RiskCodeIndex from './resources/riskCode/RiskCodeIndex';
import RiskRegulationIndex from './resources/riskRegulation/RiskRegulationIndex';
import RiskTypeIndex from './resources/riskType/RiskTypeIndex';

// Sale related
import SaleIndex from './resources/sale/SaleIndex';
import SaleShow from './resources/sale/SaleShow';
import StandardIndex from './resources/standard/StandardIndex';
import StandardShow from './resources/standard/StandardShow';
import WooCommerceIndex from './resources/woocommerce/WooCommerceIndex';
import IncomingGoodsIndex from './resources/incomingGoods/IncomingGoodsIndex';

// Stock related
import StockIndex from './resources/stock/StockIndex';
import StockShow from './resources/stock/StockShow';
import { SideBarFields } from './resources/sidebar/SideBarTypes';
import GroupIndex from "./resources/group/GroupIndex";
import BlockedAssessmentIndex from "./resources/assessment/pages/BlockedAssessmentIndex";


export type Route = {
    path: string,
    element: FC,
    breadcrumb?: string
}


/**
 * Nav item structure for desktop and mobile.
 *
 * pseudoChildPaths: Children with different URIs but should highlight nav item
 */
export type NavItem = {
    name: string|JSX.Element,
    icon?: string,
    href?: string,
    pseudoChildPaths?: string[],
    dataKey?: keyof SideBarFields
}


/**
 * Check if current route corresponds with nav item.
 *
 * @param {NavItem} item
 * @returns {boolean}
 */
export const IsCurrentNavItem = (item: NavItem): boolean => (
    item.href &&
    location.pathname.startsWith(item.href)
    || item.pseudoChildPaths?.find((path) => location.pathname.startsWith(path)) !== undefined
);


/**
 * Navigation items for sidebar and mobile menu.
 *
 * Name will be displayed in FE
 * Icon is the material icons name (for spans)
 * Href is the path that will be used in react router dom links.
 */
export const navigation: NavItem[] = [
    { name: 'Dashboard', icon: 'space_dashboard', href: '/dashboard' },

    { name: 'Productbeheer', pseudoChildPaths: [ '/compound-elements', '/excipient-elements', '/products', '/articles', '/packages', '/package-parts', '/charges' ] },
    { name: 'Producten', icon: 'developer_board', href: '/products' },
    { name: 'Artikelen', icon: 'article', href: '/articles' },
    { name: 'Charges', icon: 'qr_code', href: '/charges' },
    { name: 'Verpakkingen', icon: 'inventory_2', href: '/packages', pseudoChildPaths: [ '/package-parts' ] },

    { name: 'Voorraad beheer', pseudoChildPaths: [ '/stock', '/stock-financial', '/repacking', '/locations' ] },
    { name: 'Voorraad', icon: 'shopping_basket', href: '/stock' },
    { name: 'Uitvul opdrachten', icon: 'alt_route', href: '/repacking', dataKey: 'open_repack_orders' },
    { name: 'Magazijn', icon: 'warehouse', href: '/locations' },

    { name: 'Handel', pseudoChildPaths: [ '/purchases', '/sales', '/to-order', '/wc-import' ] },
    { name: 'Inkoop', icon: 'local_mall', href: '/purchases', dataKey: 'open_purchases' },
    { name: 'Verkoop', icon: 'local_offer', href: '/sales', dataKey: 'open_sales' },
    { name: 'Te bestellen ', icon: 'request_quote', href: '/to-order' },
    { name: 'WC import', icon: 'cloud_sync', href: '/woocommerce-import', dataKey: 'open_wc_orders' },
    { name: 'Inkomende goederen', icon: 'local_shipping', href: '/incoming-goods' },

    { name: 'Risicobeheer', pseudoChildPaths: [ '/charge-assessments', 'product-risk-reviews', '/blocked-assessments' ] },
    { name: 'Element evaluaties', icon: 'developer_board', href: '/element-reviews', dataKey: 'unreviewed_elements' },
    { name: 'Product evaluaties', icon: 'developer_board', href: '/product-risk-reviews', dataKey: 'unreviewed_products' },
    { name: 'Risico borgingen', icon: 'developer_board', href: '/charge-assessments', dataKey: 'open_charge_assessments' },
    { name: 'Gemarkeerd voor analyse', icon: 'developer_board', href: '/blocked-assessments', dataKey: 'blocked_assessments' },

    { name: 'Relaties', pseudoChildPaths: [ '/customers', '/producers' ] },
    { name: 'Klanten', icon: 'people', href: '/customers' },
    { name: 'Producenten', icon: 'factory', href: '/producers' },
    { name: 'Instellingen', icon: 'settings', href: '/settings' }
];


/**
 * List that will be read by react router dom.
 *
 * Contains path and page element reference.
 */
export const routes: Route[] = [
    // Root
    {
        path: '/dashboard',
        element: Dashboard,
        breadcrumb: 'Dashboard'
    },
    {
        path: '/profile',
        element: ProfileShow,
        breadcrumb: 'Profiel'
    },


    // Products
    {
        path: '/products',
        element: ProductsIndex,
        breadcrumb: 'Producten'
    },
    {
        path: '/products/:id',
        element: ProductShow
    },
    {
        path: '/settings/product-types',
        element: ProductTypeIndex,
        breadcrumb: 'Producttypes'
    },
    {
        path: '/settings/product-types/:id',
        element: ProductTypeShow
    },


    // Articles
    {
        path: '/articles',
        element: ArticleIndex,
        breadcrumb: 'Artikelen'
    },
    {
        path: '/articles/:id',
        element: ArticleShow
    },

    // Elements
    {
        path: '/settings/excipient-elements',
        element: ElementExcipientIndex,
        breadcrumb: 'Hulpstoffen'
    },
    {
        path: '/settings/compound-elements',
        element: ElementCompoundIndex,
        breadcrumb: 'Actieve stoffen'
    },
    {
        path: '/settings/compound-elements/:id',
        element: ElementCompoundShow
    },
    {
        path: '/settings/excipient-elements/:id',
        element: ElementExcipientShow
    },


    // Charges
    {
        path: '/charges',
        element: ChargeIndex,
        breadcrumb: 'Charges'
    },
    {
        path: '/charges/:id',
        element: ChargeShow
    },


    // Repacking
    {
        path: '/repacking',
        element: RepackingIndex,
        breadcrumb: 'Uitvul opdrachten'
    },


    // Packages
    {
        path: '/packages',
        element: PackageIndex,
        breadcrumb: 'Verpakkingen'
    },
    {
        path: '/packages/:id',
        element: PackageShow
    },
    {
        path: '/package-parts',
        element: PackagePartIndex,
        breadcrumb: 'Verpakkingsonderdelen'
    },
    {
        path: '/package-parts/:id',
        element: PackagePartShow
    },

    // Stock
    {
        path: '/stock',
        element: StockIndex,
        breadcrumb: 'Voorraad'
    },
    {
        path: '/stock/:id',
        element: StockShow
    },
    // Warehouse (Location)
    {
        path: '/locations',
        element: LocationIndex,
        breadcrumb: 'Magazijn'
    },
    {
        path: '/locations/:id',
        element: LocationShow
    },

    // WC import
    {
        path: '/woocommerce-import',
        element: WooCommerceIndex,
        breadcrumb: 'WooCommerce import'
    },

		// Inkomende goederen
    {
        path: '/incoming-goods',
        element: IncomingGoodsIndex,
        breadcrumb: 'Inkomende goederen'
    },

    // Purchases
    {
        path: '/purchases',
        element: PurchaseIndex,
        breadcrumb: 'Inkooporders'
    },
    {
        path: '/purchases/:id',
        element: PurchaseShow
    },


    // Producers
    {
        path: '/producers',
        element: ProducerIndex,
        breadcrumb: 'Producent'
    },
    {
        path: '/producers/:id',
        element: ProducerShow
    },

    // Customers
    {
        path: '/customers',
        element: CustomerIndex,
        breadcrumb: 'Klanten'
    },
    {
        path: '/customers/:id',
        element: CustomerShow
    },

    // Sales
    {
        path: '/sales',
        element: SaleIndex,
        breadcrumb: 'Verkooporders'
    },
    // Sales
    {
        path: '/sales/:id',
        element: SaleShow
    },

    // To order
    {
        path: '/to-order',
        element: ToOrderIndex,
        breadcrumb: 'Te bestellen'
    },

    // Risk reviews
    {
        path: '/product-risk-reviews',
        element: ProductRiskReviewIndex,
        breadcrumb: 'Product evaluaties'
    },
    {
        path: '/element-reviews',
        element: ElementReviewIndex,
        breadcrumb: 'Element evaluaties'
    },
    {
        path: '/charge-assessments',
        element: AssessmentIndex,
        breadcrumb: 'Risico borgingen'
    },
    {
        path: '/blocked-assessments',
        element: BlockedAssessmentIndex,
        breadcrumb: 'Geblokkeerde assessments'
    },

    // Settings
    // Product settings

    {
        path: '/settings/element-categories',
        element: CategoryIndex,
        breadcrumb: 'Grondstof categorieën'
    },
    {
        path: '/settings/element-categories/:id',
        element: CategoryShow
    },
    {
        path: '/settings/compounds',
        element: CompoundIndex,
        breadcrumb: 'Grondstof verbindingen'
    },
    {
        path: '/settings/compounds/:id',
        element: CompoundShow
    },
    {
        path: '/settings/excipients',
        element: ExcipientIndex,
        breadcrumb: 'Hulpstof functies'
    },
    {
        path: '/settings/excipients/:id',
        element: ExcipientShow
    },
    {
        path: '/settings/attributes',
        element: AttributeIndex,
        breadcrumb: 'Producteigenschappen'
    },
    {
        path: '/settings/attributes/:id',
        element: AttributeShow
    },

    // Risk settings
    {
        path: '/settings/risks',
        element: RiskIndex,
        breadcrumb: 'Risico\'s'
    },
    {
        path: '/settings/risks/:id',
        element: RiskShow
    },
    {
        path: '/settings/risk-assurances',
        element: RiskAssuranceIndex,
        breadcrumb: 'Risico borgingen'
    },
    {
        path: '/settings/risk-regulations',
        element: RiskRegulationIndex,
        breadcrumb: 'Risico regulaties'
    },
    {
        path: '/settings/risk-codes',
        element: RiskCodeIndex,
        breadcrumb: 'Risico codes'
    },
    {
        path: '/settings/risk-types',
        element: RiskTypeIndex,
        breadcrumb: 'Risico types'
    },

    // Producer standard
    {
        path: '/settings/producer-standards',
        element: StandardIndex,
        breadcrumb: 'Producentstandaarden'
    },
    {
        path: '/settings/producer-standards/:id',
        element: StandardShow
    },

    // Printers
    {
        path: '/settings/printers',
        element: PrinterIndex,
        breadcrumb: 'Printers'
    },
    {
        path: '/settings/printers/:id',
        element: PrinterShow
    },

    // Printer Jobs
    {
        path: '/settings/printer-jobs',
        element: PrinterJobIndex,
        breadcrumb: 'Printers'
    },
    {
        path: '/settings/printer-jobs/:id',
        element: PrinterJobShow
    },


    // System settings
    {
        path: '/settings/status',
        element: StatusPage,
        breadcrumb: 'Status'
    },

    {
        path: '/settings/ingredients',
        element: IngredientIndex,
        breadcrumb: 'Ingredienten'
    },
    {
        path: '/settings/ingredients/:id',
        element: IngredientShow
    },

    // account settings
    {
        path: '/settings/accounts',
        element: AccountIndex
    },
    // Group settings
    {
        path: '/settings/groups',
        element: GroupIndex
    },

    // Settings
    {
        path: '/settings',
        element: Settings,
        breadcrumb: 'Instellingen'
    },

    // Auth
    {
        path: '/login',
        element: Login,
        breadcrumb: 'Inloggen'
    },
    {
        path: '/logout',
        element: Logout,
        breadcrumb: 'Uitloggen'
    },
    {
        path: '/forgot-password',
        element: ForgotPassword,
        breadcrumb: 'Wachtwoord vergeten'
    },
    {
        path: '/reset-password',
        element: ResetPassword,
        breadcrumb: 'Wachtwoord wijzigen'
    },
    {
        path: '/cancel-password-reset',
        element: CancelToken,
        breadcrumb: 'Wachtwoord token annuleren'
    },

    {
        path: '/test',
        element: Test,
        breadcrumb: 'Test page'
    },
    {
        path: '/test-index',
        element: TestIndex,
        breadcrumb: 'Test index page'
    },
    {
        path: '/new-table',
        element: TableTestIndex,
        breadcrumb: 'Test index table page'
    },
    {
        path: '/new-table/:id',
        element: TableTestShow,
        breadcrumb: 'Test index table page'
    }

];
