import React, {FC} from "react";
import IndexPage from "../../templates/pages/IndexPage";
import IncomingGoodsTable from './IncomingGoodsTable';


const IncomingGoodsIndex: FC = (): JSX.Element => <IndexPage
    title={'Inkomende goederen'}
    Table={IncomingGoodsTable}
/>

export default IncomingGoodsIndex;
