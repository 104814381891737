import { ProductModel } from './ProductTypes';


export const formatProductNumber = (productNumber?: number|string) => String(productNumber ?? 0).padStart(4, '0')

export const getProductVersionDisplayNameByProduct = (product: ProductModel) => {

    const version = `v${ product.version ?? 1.0 }`
    const latestVersionAssociated = (product.productVersionLogs ?? [])
        .reduce((prev, current) => (prev && prev.id!>current.id!) ?prev :current);

    let internalComment = ''
    if (latestVersionAssociated.internal_comment !== undefined) {
        internalComment = latestVersionAssociated.internal_comment
    }
    return `${version} ${internalComment != '' ? '-' : ''} ${ internalComment }`
}