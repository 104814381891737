import React, { FC, useEffect, useState } from 'react';
import Select from '../../../lib/samfe/components/Form/Generic/Select/Select';
import { sortByDate } from '../../../lib/samfe/modules/Parse/Date';
import { sortByNumber } from '../../../lib/samfe/modules/Parse/Number';
import { getFullArticleName } from '../../article/ArticleFunctions';
import { batchCodeLabel, expirationDateLabel } from '../../charge/ChargeFunctions';
import { ChargeStatus } from '../../charge/ChargeTypes';
import { SaleRowDto } from '../../sale/pivot/SaleRowTypes';
import { WcArticleItem, WcChargeItem, WooCommerceImportItem } from './WooCommerceImportTypes';


type ChargeDisplayItem = {
    batchcode: JSX.Element|string,
    expiration_date: string,
    isFavourite: boolean,
    isBackorder: boolean,
    amountClaimed: number,
    amountUsable: number,
    status: ChargeStatus
}

type Props = {
    customerNumber: number|undefined,
    item: WooCommerceImportItem,
    handleRows: (sku: string, saleRowDtos: SaleRowDto[]) => void
}

const WooCommerceImportRowItem: FC<Props> = ({ customerNumber, item, handleRows }): JSX.Element => {

    const { product, wcData } = item;
    const { articles } = product;

    const [ currentArticle, setCurrentArticle ] = useState<WcArticleItem>();
    const [ chargeDisplayItems, setChargeDisplayItems ] = useState<ChargeDisplayItem[]>([]);
    const [ saleRows, setSaleRows ] = useState<SaleRowDto[]>([]);
    const [ canMatch, setCanMatch ] = useState(true);


    /**
     * Visual representation of a backorder line item.
     * @param {number} backOrderQuantity
     * @returns {ChargeDisplayItem}
     */
    const getBackOrderDisplayItem = (backOrderQuantity: number): ChargeDisplayItem => ({
        batchcode: 'Backorder',
        isBackorder: true,
        isFavourite: false,
        amountClaimed: backOrderQuantity,
        amountUsable: 0,
        expiration_date: '-',
        status: 'processed'
    });


    /**
     * Sale row for backorder
     * @param {number} articleId
     * @param {number} backOrderQuantity
     * @returns {SaleRowDto}
     */
    const getBackOrderSaleRow = (articleId: number, backOrderQuantity: number): SaleRowDto => ({
        article_id: articleId,
        quantity: backOrderQuantity,
        sku: wcData.variationSku ?? wcData.sku,
        colli: 1,
        price_per_amount: wcData.total / wcData.quantity,
        wc_product_id: `${ wcData.productId }`,
        status: 'backorder'
    });


    /**
     * Handler for parsing charge to display item.
     *
     * @param {number} amountClaimed
     * @param {WcChargeItem} charge
     * @returns {ChargeDisplayItem}
     */
    const chargeToDisplayItem = (amountClaimed: number, charge?: WcChargeItem): ChargeDisplayItem => {
        if (!charge) {
            return getBackOrderDisplayItem(amountClaimed);
        }
        return ({
            batchcode: batchCodeLabel(charge),
            isBackorder: false,
            isFavourite: charge.favourite === true,
            amountClaimed: amountClaimed,
            amountUsable: charge.stock?.usable_stock ?? 0,
            expiration_date: charge.expiration_date ?? 'Onbekend',
            status: 'processed'
        });
    };


    /**
     * When called, it determines what charges should be used for selected article.
     */
    const handleArticleChange = (): void => {
        let remainingAmount = parseInt(`${ wcData.quantity }`);
        if (!currentArticle) {
            // @todo error handling
            return;
        }

        if (currentArticle.charges.length === 0) {
            const backOrderDisplayItem = getBackOrderDisplayItem(remainingAmount);
            setChargeDisplayItems([ backOrderDisplayItem ]);
            const backOrderSaleRow = getBackOrderSaleRow(currentArticle.id, remainingAmount);
            setSaleRows([ backOrderSaleRow ]);
            return;
        }

        const displayItems: ChargeDisplayItem[] = [];
        const saleRows: SaleRowDto[] = [];

        const sortedCharges = (): WcChargeItem[] => {
            const charges: WcChargeItem[] = currentArticle.charges ?? [];
            if (charges.length === 0) {
                return [];
            }

            return charges.sort((a, b) => {
                // Sort by usable stock (lowest amount first)
                const sortedByUsableStock = sortByNumber(a.stock.usable_stock, b.stock.usable_stock);

                // 1. Customer number in batchcode has the highest priority
                let customerNumberSorted = 0;
                if (customerNumber) {
                    const aHasNumber = a.batchcode?.includes(`[${ customerNumber }]`) ?1 :0;
                    const bHasNumber = b.batchcode?.includes(`[${ customerNumber }]`) ?1 :0;

                    if (aHasNumber !== bHasNumber) {
                        // Place charges with the customer number first
                        customerNumberSorted = bHasNumber - aHasNumber;

                        // If expiration dates are equal, sort by usable stock
                        if (a.expiration_date === b.expiration_date) {
                            customerNumberSorted = sortedByUsableStock;
                        }
                    }
                }

                // 2. Favourite has the second priority
                let favouriteSorted = 0;
                const aIsFavourite = a.favourite ?1 :0;
                const bIsFavourite = b.favourite ?1 :0;

                if (aIsFavourite !== bIsFavourite) {
                    // Place favourite charges first
                    favouriteSorted = bIsFavourite - aIsFavourite;

                    // If expiration dates are equal, sort by usable stock
                    if (a.expiration_date === b.expiration_date) {
                        favouriteSorted = sortedByUsableStock;
                    }
                }

                // 3. Sort by expiration date (First In First Out)
                let expirationDateSorted = sortByDate(a.expiration_date, b.expiration_date);

                // If expiration dates are equal, sort by usable stock
                if (a.expiration_date === b.expiration_date) {
                    expirationDateSorted = sortedByUsableStock;
                }

                // Return the sorting result based on the priority
                if (customerNumberSorted !== 0) {
                    return customerNumberSorted;
                } else if (favouriteSorted !== 0) {
                    return favouriteSorted;
                } else if (expirationDateSorted !== 0) {
                    return expirationDateSorted;
                } else {
                    // If all sorting criteria are equal, return 0
                    return 0;
                }
            });
        };

        for (const charge of sortedCharges()) {
            const availableAmount = charge.stock.usable_stock ?? 0;
            const subtractAmount = availableAmount>remainingAmount
                                   ?remainingAmount
                                   :availableAmount;

            displayItems.push(chargeToDisplayItem(subtractAmount, charge));
            saleRows.push({
                article_id: currentArticle.id,
                charge_id: charge.id,
                quantity: subtractAmount,
                sku: wcData.variationSku ?? wcData.sku,
                colli: 1,
                price_per_amount: wcData.total / wcData.quantity,
                wc_product_id: `${ wcData.productId }`,
                status: 'to send'
            });

            remainingAmount -= subtractAmount;
            if (remainingAmount === 0) {
                break;
            }
        }

        if (remainingAmount>0) {
            displayItems.push(getBackOrderDisplayItem(remainingAmount));
            saleRows.push(getBackOrderSaleRow(currentArticle.id, remainingAmount));
        }

        setChargeDisplayItems(displayItems);
        setSaleRows(saleRows);
    };


    /**
     * Init default selected article.
     */
    useEffect(() => {
        if (!articles || articles.length == 0) {
            return;
        }
        const initialArticle = articles.find(article => article.number == wcData.sku);
        if (initialArticle === undefined) {
            setCanMatch(false);
        }
        const firstArticle = initialArticle ?? articles[0];
        setCurrentArticle(firstArticle);
    }, []);


    /**
     * If article is set, convert charges to sale rows and display items.
     */
    useEffect(() => {
        handleArticleChange();
    }, [ currentArticle ]);


    /**
     * Invoke parent provided method on sale rows change.
     */
    useEffect(() => {
        handleRows(wcData.sku, saleRows);
    }, [ saleRows ]);


    return <tr>
        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-aqua sm:pl-0 align-text-top">
            { product.name }
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-graphite align-text-top">
            { wcData.sku }
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-graphite align-text-top">
            <Select
                name={ 'todo' }
                label={ '' } invalid={ {
                isInvalid: !canMatch,
                message: 'Kan artikel niet matchen!'
            } }
                onChange={ option => {
                    setCurrentArticle(articles.find(article => article.id == option.value));
                    setCanMatch(true);
                } }
                options={ articles.map((article, i) => ({
                    displayName: getFullArticleName(article),
                    value: article.id,
                    selected: currentArticle ?currentArticle.id === article.id :i === 0
                })) }
            />
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-graphite align-text-top">
            { wcData.quantity }
        </td>
        <td className="whitespace-nowrap px-3 py-4 text-sm text-graphite align-text-top">
            <ul className={ 'list-disc list-inside' }>
                { chargeDisplayItems.map((item, i) => <li key={ i }>
                    { item.batchcode } { !item.isBackorder && `(${ expirationDateLabel(item.expiration_date) })` } ({ item.amountClaimed } v/d { item.amountUsable } stuks)
                </li>) }
            </ul>
        </td>
    </tr>;
};
export default WooCommerceImportRowItem;