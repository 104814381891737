import React, { FC, useMemo } from 'react';
import ProducerForm from './ProducerForm';
import { ProducerModel, ProducerRelationsBluePrint } from './ProducerTypes';
import useProducer from './useProducer';
import {ExtendTableProps} from "../../components/table/types/Table";
import Table from "../../components/table";
import {TableColumns} from "../../components/table/types/TableRow";

type Props = Required<ExtendTableProps>

const ProducerTable: FC<Props> = () => {

    const httpHook = useProducer();

    const rows = useMemo((): TableColumns<ProducerModel, ProducerRelationsBluePrint>[] => [
        {
            header: {
                children: 'Producent',
                sortCol: 'name'
            },
            column: (item) => ({
                children: item.name,
                linkTo: (item) => `${item.id}`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Contact persoon',
                sortCol: 'contact_person'
            },
            column: (item) => ({
                children: item.contact_person
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Email',
                sortCol: 'email'
            },
            column: (item) => ({
                children: item.email
            }),
            type: 'mail'
        },
        {
            header: {
                children: 'Telefoon',
                sortCol: 'phone'
            },
            column: (item) => ({
                children: item.phone
            }),
            type: 'tel'
        },
        {
            header: {
                children: 'Actief',
                sortCol: 'active'
            },
            column: (item) => ({
                children: item.active ? 'Ja' :'Nee'
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Notitie',
                sortCol: 'comments'
            },
            column: (item) => ({
                children: item.comments
            }),
            type: 'text'
        }
    ], []);

    return (<>
        <Table
            id={ 'producers' }
            rows={ rows }
            http={ {
                hook: httpHook,
                searchCols: ['name', 'email', 'phone', 'contact_person'],
                sortCol: 'name',
                filter: {
                  column: 'active',
                  value: 1
                },
                filters: [
                    {
                        displayName: 'Actief',
                        column: 'active',
                        value: 1
                    },
                    {
                        displayName: 'Inactief',
                        column: 'active',
                        value: 0
                    }
                ],
            } }
            forms={ {
                edit: {
                    Form: ProducerForm,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.name }`,
                    resourceName: () => 'Producent'
                },
            } }
        />
    </>
    );
};

export default ProducerTable;
