import React, { FC, useCallback, useMemo } from 'react';
import Table from '../../../components/table';
import { ExtendTableProps } from '../../../components/table/types/Table';
import { TableColumns } from '../../../components/table/types/TableRow';
import { getFullArticleName } from '../../article/ArticleFunctions';
import { batchCodeLabel, expirationDateLabel } from '../../charge/ChargeFunctions';
import PurchaseRowForm from '../../purchase/pivot/purchaseRow/PurchaseRowForm';
import { PurchaseRowModel, PurchaseRowRelationsBluePrint } from '../../purchase/pivot/purchaseRow/PurchaseRowTypes';
import { usePurchaseRowResource } from '../../purchase/pivot/purchaseRow/usePurchaseRow';
import { parseChargeLocations } from '../../../lib/samfe/modules/Parse/Location';
import SaleRowForm from './SaleRowForm';
import { SaleRowModel, SaleRowRelationsBluePrint, SaleRowStatusTranslation } from './SaleRowTypes';
import useSaleRow from './useSaleRow';


type Props = Omit<ExtendTableProps, 'parentId'>&{
    parentId: number
}

const SaleRowTable: FC<Props> = ({ parentId, htmlBefore, notification }): JSX.Element => {

    const httpHook = useSaleRow(parentId);


    const rows = useMemo((): TableColumns<SaleRowModel, SaleRowRelationsBluePrint>[] => [
        {
            header: {
                children: 'Aantal',
                sortCol: 'quantity'
            },
            column: (item) => ({
                children: item.quantity
            }),
            type: 'numeric',
            style: {
                width: '4rem'
            }
        },
        {
            header: {
                children: 'Artikelnummer'
            },
            column: (item) => ({
                children: getFullArticleName(item.article, false),
                linkTo: (item) => `/articles/${ item.article_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Naam'
            },
            column: (item) => ({
                children: item.article?.product?.name,
                linkTo: (item) => `/products/${ item.charge?.product_id ?? item.article?.product_id }`
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Versie'
            },
            column: (item) => ({
                children: item.charge_id ?`v${ item.charge?.product?.version }` :'-'
            }),
            type: 'text'
        },
        {
            header: {
                children: 'Charge'
            },
            column: (item) => ({
                children: batchCodeLabel(item.charge),
                linkTo: (item) => `/charges/${ item.charge?.id }`
            }),
            type: 'element'
        },
        {
            header: {
                children: 'THT'
            },
            column: (item) => ({
                children: expirationDateLabel(item.charge?.expiration_date, true)
            }),
            type: 'element'
        },
        {
            header: {
                children: 'Inkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.purchase_price
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Verkoopprijs'
            },
            column: (item) => ({
                children: item.financialData?.total
            }),
            type: 'currency'
        },
        {
            header: {
                children: 'Winstmarge'
            },
            column: (item) => ({
                children: item.financialData?.profit_margin_percentage
            }),
            type: 'percentage'
        },
        {
            header: {
                children: 'Locatie',
            },
            column: (item) => ({
                children: parseChargeLocations(item.charge?.chargeLocations ?? [])
            }),
            type: 'percentage'
        },
        {
            header: {
                children: 'Status',
                sortCol: 'status'
            },
            column: (item) => ({
                children: SaleRowStatusTranslation(item.status)
            }),
            type: 'text'
        }
    ], []);


    const postProcessData = useCallback((items: SaleRowModel[]): SaleRowModel[] => {
        return items.sort((a, b) => {
            const aKey = a.article?.number;
            const bKey = b.article?.number;
            return (aKey ?? '').localeCompare((bKey ?? ''));
        });
    }, []);

    return <>
        <Table
            id={ 'sale-rows' }
            notification={ notification }
            htmlBefore={ htmlBefore }
            postProcessData={ postProcessData }
            rows={ rows }
            http={ {
                hook: httpHook,
                showAll: true,
                disableDeArchiving: true,
                searchCols: [ 'article.number', 'article.product.name', 'charge.batchcode' ],
                with: [
                    'article',
                    'article.product',
                    'article.package',
                    'article.package.packageParts.part',
                    'charge',
                    'charge.product',
                    'charge.chargeLocations.location.group',
                    'financialData'
                ],
                sortCol: 'id',
                sortDir: 'DESC'
            } }
            forms={ {
                edit: {
                    Form: SaleRowForm,
                    parentId: (item) => item.sale_id,
                    id: (record) => record.id,
                },
                archive: {
                    id: (record) => record.id,
                    itemName: (record) => `${ record.id }`,
                    resourceName: () => 'Verkoopregel',
                    disableForRow: (item) => item.status == 'sent'
                }
            } }
        />
    </>;
};

export default SaleRowTable;