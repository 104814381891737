import useAxios, { AxiosContentType, Params, useAxiosContext } from './useAxios';
import {MinimumModel} from "../../types/ModelTypes";
import useToaster from "../../components/Toaster/useToaster";
import usePagination, {UsePagination} from "./usePagination";


export type ExtendModel = MinimumModel


export type RequestParams<Dto, RelationBlueprint extends string> = Params<Dto, RelationBlueprint> & Dto


type Props = {
    baseUrl?: string,
    endpoint: string,
    contentType?: AxiosContentType,
    withCredentials?: boolean
}

export type ListResponse<Model> = {
    items: Model[],
    limit: number|'all',
    order: "ASC"|"DESC",
    orderColumn: keyof Model,
    page: number,
    total: number
}


export type UseHttp<Model extends ExtendModel, Dto extends Partial<Model>, RelationBlueprint extends string> = {
    getItem: (id?: number, config?: RequestParams<Partial<Model>, RelationBlueprint>, omitId?: boolean) => Promise<Model|undefined>,
    getList: (config?: RequestParams<Partial<Model>, RelationBlueprint>) => Promise<Model[]>,
    create: (payload: Dto) => Promise<{ id: number }|undefined>
    update:  (id: number|undefined, payload: Dto) => Promise<Model|undefined>,
    destroy: (id: number) => Promise<{ id: number }|undefined>,
    pagination: UsePagination<Model>,
};

export type UseHttpHook<Model extends ExtendModel, Dto extends Partial<Model>, RelationBlueprint extends string> = (parentId?:number) => UseHttp<Model, Dto, RelationBlueprint>

const mockModel: MinimumModel = ({id: 1});

/**
 * Get resources by given params.
 */
const useHttp = <Model, Dto, RelationBlueprint extends string>({
    baseUrl,
    endpoint,
    contentType = 'application/json',
    withCredentials = true
}: Props): UseHttp<Model, Dto, RelationBlueprint> => {

    const axios = useAxios({ baseUrl, contentType, withCredentials });
    const {setToasterProps} = useToaster();
    const {pagination, setPagination} = usePagination<Model>();
    const {isTest} = useAxiosContext();

    const setToasterFeedback = (title: string, message: string|undefined = undefined) => {
        setToasterProps({
            title,
            message,
            type: 'success',
            show: true,
        });
    }

    /**
     *
     * @param id
     * @param config
     * @param omitId
     */
    const getItem = async (id?: number, config?: RequestParams<Partial<Model>, RelationBlueprint>, omitId: boolean = false): Promise<Model | undefined> => id||omitId
        ? !isTest
            ? await axios.get<Model, RelationBlueprint>(`${endpoint}/${id??''}`, config)
                .then(r => r.data)
                .catch(() => undefined)
            : mockModel as  Model
        : undefined

    /**
     *
     * @param config
     */
    const getList = async (config?: RequestParams<Partial<Model>, RelationBlueprint>): Promise<Model[]> => !isTest
        ? await axios
            // @ts-ignore
        .get<ListResponse<Model>, RelationBlueprint>(endpoint, config)
        .then(r => {
            setPagination({
                ...pagination,
                totalItems: r.data.total,
                currentPage: r.data.page,
            })
            return r.data.items ?? []
        })
        .catch(() => [])
        : [mockModel] as Model[]


    const create = async (payload: Dto): Promise<{ id: number } | undefined> => !isTest
        ? await axios.post<Dto, { id: number }>(endpoint, payload)
            .then(r => {
                setToasterFeedback('Opgeslagen')
                return r.data;
            })
            .catch(() => undefined)
        : mockModel as { id: number }


    const update = async (id: number|undefined, payload: Dto): Promise<Model | undefined> => !isTest
        ? await axios
            .put<Dto, Model>(`${endpoint}/${id ?? ''}`, payload)
            .then(r => {
                setToasterFeedback('Aangepast')
                return r.data;
            })
            .catch(() => undefined)
        : mockModel as  Model


    const destroy = async (id: number): Promise<{ id: number } | undefined> => !isTest
        ? await axios
            .delete<{ id: number }>(`${endpoint}/${id}`)
            .then(r => {
                setToasterFeedback('Gearchiveerd')
                return r.data;
            })
            .catch(() => undefined)
        : mockModel as { id: number }

    return {
        getItem,
        getList,
        create,
        update,
        destroy,
        pagination: {pagination, setPagination},
    };
}
export default useHttp;
