import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MaterialIconName } from 'ts-material-icon-name-list';
import { classNames } from '../../modules/Parse/String';
import Icon from '../Icon/Icon';
import './tab.scss';


export type Tab = {
    id: string,
    name: string,
    element: JSX.Element
    hasError?: boolean|null,
    action?: {
        icon?: MaterialIconName,
        callback: () => void
    }
}

type Props = {
    tabs: Tab[]
}

const Tabs: FC<Props> = ({ tabs }): JSX.Element => {

    const [ searchParams, setSearchParams ] = useSearchParams();

    /**
     * When a tab is never opened, it will not be rendered.
     * When opened for at least once, its state will be persisted to reduce unnecessary data processing.
     */
    const [ wasOpened, setWasOpened ] = useState<string[]>([]);

    /**
     * Tab search param identifier
     */
    const currentTabKey: string = useMemo(() => 'current-tab', []);


    /**
     * Fallback tab id if none provided in url.
     */
    const initialTabId: string|undefined = useMemo(() => {
        return tabs?.[0]?.id;
    }, [ tabs ]);


    /**
     * Check if tab id is present in url
     */
    const hasTabId: boolean = useMemo(() => {
        return searchParams.has(currentTabKey);
    }, [ searchParams, currentTabKey ]);


    /**
     * Current selected tab id by fallback or by user
     */
    const currentTabId: string = useMemo(() => {
        if (hasTabId) {
            return searchParams.get(currentTabKey)!;
        }
        if (initialTabId !== undefined) {
            return initialTabId;
        }
        return '';
    }, [ hasTabId, searchParams, currentTabKey, initialTabId ]);


    /**
     * Update search params with given tab id
     */
    const handleTabId = (tabId: string, shouldReplace: boolean = false): void => {
        searchParams.set('current-tab', tabId);
        setSearchParams(searchParams, { replace: shouldReplace });
    };


    /**
     * Check if tab param exist or assign first in list.
     * Replace url when id not present in id to prevent double-clicking on returning back to index page.
     */
    useEffect((): void => {
        if (!hasTabId && initialTabId != undefined) {
            handleTabId(initialTabId, true);
        }
    }, [ hasTabId, initialTabId ]);


    /**
     * Scroll horizontal i.o. with mouse on hover to see all items in tab nav.
     */
    useEffect(() => {
        const tabNavDesktop = document.getElementById('tabNavDesktop')!;
        const handleHorizontalScroll = (e: WheelEvent) => {
            e.preventDefault();
            const deltaScroll = e.deltaY;
            tabNavDesktop.scrollLeft += deltaScroll;
        };
        tabNavDesktop.addEventListener('wheel', handleHorizontalScroll);
        return (): void => {
            tabNavDesktop.removeEventListener('wheel', handleHorizontalScroll);
        };
    }, []);


    /**
     * Execute action + navigate to tab associated with the action
     */
    const handleTabActionClick = (tab: Tab): void => {
        tab.action?.callback();
        handleTabId(tab.id);
    };

    return (
        <>
            <div className={ 'border-y border-y-gray-200 -mx-4 sm:-mx-10 lg:-mx-12 py-2' }>
                <div className={ 'mx-4 sm:mx-10 lg:mx-12' }>
                    {/* Mobile select */ }
                    <div className="lg:hidden py-2">
                        <label htmlFor="tabs" className="sr-only">Select a tab</label>
                        <select
                            id="tabs"
                            name="tabs"
                            defaultValue={ currentTabId }
                            onChange={ (e) => handleTabId(e.currentTarget.value) }
                            className="block w-full pl-3 pr-10 py-2 text-base border-gray-200 focus:outline-none focus:ring-aqua focus:border-aqua sm:text-sm rounded-md"
                        >
                            { tabs.map(tab => (
                                <option key={ tab.id } value={ tab.id }>
                                    { tab.name }
                                </option>
                            )) }
                        </select>
                    </div>

                    {/* Desktop menu */ }
                    <div className="hidden lg:block">
                        <nav id={ 'tabNavDesktop' } aria-label="Tabs" className="flex space-x-8 overflow-x-auto pt-2 pb-3">
                            { tabs.map(tab => (
                                <div key={ tab.id } className={ classNames('relative', tab.action !== undefined && 'pr-5') }>
																	
                                    <button
                                        key={ tab.id }
                                        onClick={ () => handleTabId(tab.id) }
                                        aria-current={ tab.id === currentTabId ?'page' :undefined }
                                        className={ classNames('whitespace-nowrap font-medium text-sm',
                                            classNames('pb-1 px-1 border-b-[0.2rem]',
                                                currentTabId === tab.id
                                                ?'border-aqua text-aqua border-b-[0.2rem] border-aqua'
                                                :'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                                            )
                                        ) }
                                    >
                                        { tab.hasError === true && <span className={ 'mr-1 mt-[0.2rem] material-icons text-carrot' }>warning</span> }
                                        { tab.name }
                                    </button>

                                    { tab.action !== undefined && (
                                        <button onClick={ () => handleTabActionClick(tab) } className={ 'absolute right-0 top-0.5' }>
                                            <Icon materialIconName={ tab.action.icon ?? 'edit' } className={ 'text-aqua hover:text-aqua-hover active:text-aqua-active text-[1rem]' }/>
                                        </button>
                                    ) }

                                </div>
                            )) }
                        </nav>
                    </div>
                </div>
            </div>

            {/* Tabs */ }
            { tabs.filter(tab => {
                    const isCurrent = tab.id === currentTabId;
                    const openedBefore = wasOpened.includes(tab.id);
                    if (isCurrent && !openedBefore) {
                        setWasOpened([ ...wasOpened, tab.id ]);
                    }
                    return isCurrent || openedBefore;
                })
                .map(tab => <div
                    {...(tab.id == currentTabId ? {"aria-current": true}: {})}
                    key={ tab.id }
                    className={ classNames(tab.id !== currentTabId && 'hidden') }
                >
                    { tab.element }
                </div>) }
        </>
    );
};

export default Tabs;