import { Combobox, Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon, ExclamationCircleIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Bars3Icon } from '@heroicons/react/24/outline';
import React, { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { classNames } from '../../lib/samfe/modules/Parse/String';
import useCurrentUser from '../../resources/user/useCurrentUser';


export const NAVBAR_HEIGHT_NUM_IN_REM = 3;
export const NAVBAR_HEIGHT_STR_IN_REM = `${ NAVBAR_HEIGHT_NUM_IN_REM }rem`;

const userNavigation = [
    // { name: 'Your profile', href: '#' },
    { name: 'Profiel', href: '/profile' },
    { name: 'Uitloggen', href: '/logout' }
];


type HeroIconSvg = (props: React.SVGProps<SVGSVGElement>&{ title?: string; titleId?: string; }) => JSX.Element;
type Item = {
    name: string,
    description?: string,
    path: string,
    icon: string|HeroIconSvg,
}

const items: Item[] = [
    // Root
    {
        path: '/dashboard',
        name: 'Home',
        description: 'Home sweet Home',
        icon: 'home'
    },


    // Products
    {
        path: '/products',
        name: 'Producten',
        description: 'Beheer risico\'s, eigenschappen en grondstoffen.',
        icon: 'developer_board'
    },
    {
        path: '/settings/product-types',
        name: 'Product types',
        description: 'Beheer de types van producten zoals pillen, capsules etc.',
        icon: 'label'
    },


    // Articles
    {
        path: '/articles',
        name: 'Artikelen',
        description: 'Beheer verpakkingen en inhoud en producenten.',
        icon: 'article'
    },

    // Elements
    {
        path: '/settings/compound-elements',
        name: 'Actieve Grondstoffen',
        description: 'Beheer risico\'s, functies en verbindingen.',
        icon: 'spa'
    },
    {
        path: '/settings/excipient-elements',
        name: 'Hulpstoffen',
        description: 'Beheer risico\'s, functies en verbindingen.',
        icon: 'spa'
    },

    // Charges
    {
        path: '/charges',
        name: 'Charges',
        description: 'Beheer risico\'s, voorraad en risico analyses.',
        icon: 'qr_code'
    },

    // Repacking
    {
        path: '/repacking',
        name: 'Uitvul opdrachten',
        description: 'Beheer uitvul opdrachten.',
        icon: 'alt_route'
    },


    // Packages
    {
        path: '/packages',
        name: 'Verpakkingen',
        description: 'Beheer verpakkingen en bijbehorende onderdelen.',
        icon: 'inventory_2'
    },
    {
        path: '/package-parts',
        name: 'Verpakkingsonderdelen',
        description: 'Beheer verpakkingsonderdelen en voorraad.',
        icon: 'inventory_2'
    },

    // Stock
    {
        path: '/stock',
        name: 'Voorraad',
        description: 'Bekijk de actuele voorraad van artikelen en producten',
        icon: 'shopping_basket'
    },
    // Stock
    {
        path: '/warehouse',
        name: 'Magazijn',
        description: 'Beheer magazijnlocaties van charges.',
        icon: 'warehouse'

    },

    // WC import
    {
        path: '/wc-import',
        name: 'WooCommerce import',
        description: 'Importeer orders vanuit WooCommerce.',
        icon: 'cloud_sync'

    },

		// Incomming goods
    {
        path: '/incoming-goods',
        name: 'Inkomende goederen',
        description: 'Verzameling van Inkoop en Uitvulopdrachten door Zwik.',
        icon: 'local_shipping'
    },

    // Purchases
    {
        path: '/purchases',
        name: 'Inkoop',
        description: 'Beheer inkopen bij producenten.',
        icon: 'local_mall'

    },

    // Producers
    {
        path: '/producers',
        name: 'Producenten',
        description: 'Beheer contactgegevens en artikelen.',
        icon: 'factory'

    },

    // Sales
    {
        path: '/sales',
        name: 'Verkoop',
        description: 'Beheer verkooporders, charges en artikelen.',
        icon: 'local_offer'

    },

    // Settings
    // Product settings
    {
        path: '/settings/ingredients',
        name: 'Ingrediënten',
        description: 'Product ingredienten declaraties voor labels.',
        icon: 'category'

    },
    {
        path: '/settings/element-categories',
        name: 'Grondstof categorieën',
        description: 'Beheer categorieën zoals vitaminen, mineralen etc.',
        icon: 'label'

    },
    {
        path: '/settings/compounds',
        name: 'Grondstof verbindingen',
        description: 'Beheer verbindingen en bijbehorende risico\'s',
        icon: 'hub'

    },
    {
        path: '/settings/excipients',
        name: 'Hulpstof functies',
        description: 'Beheer de functies van hulpstoffen.',
        icon: 'control_point_duplicate'

    },
    {
        path: '/settings/attributes',
        name: 'Product eigenschappen',
        description: 'Beheer de eigenschappen voor producten zoals allergenen, houdbaarheid etc.',
        icon: 'add_link'

    },
    // Purchases
    {
        path: '/to-order',
        name: 'Te Bestellen Lijst',
        description: 'Controlleer de suggesties voor aankopen voor actuele producten.',
        icon: 'balance'

    },
    // Customers
    {
        path: '/customers/',
        name: 'Klantenlijst',
        description: 'Beheer klanten.',
        icon: 'people'

    },

    // Risk settings
    {
        path: '/settings/risks',
        name: 'Risico\'s',
        description: 'Beheer risico\'s voor grondstoffen',
        icon: 'gpp_maybe'

    },
    {
        path: '/settings/risk-assurances',
        name: 'Risico borgingen',
        description: 'Beheer de borgingen voor grondstoffen',
        icon: 'fact_check'

    },
    {
        path: '/settings/risk-regulations',
        name: 'Risico regulaties',
        description: 'Beheer de regulaties en wetgevingen voor grondstoffen',
        icon: 'balance'

    },
    {
        path: '/settings/risk-codes',
        name: 'Risico codes',
        description: 'Beheer de risico codes voor grondstoffen',
        icon: 'report'

    },
    {
        path: '/settings/risk-types',
        name: 'Risico types',
        description: 'Beheer de risico types voor grondstoffen',
        icon: 'health_and_safety'

    },


    // Settings
    {
        path: '/settings',
        name: 'Instellingen',
        description: 'Instellingen overzicht voor producten en risico\'s',
        icon: 'settings'

    },


    // System settings
    {
        path: '/settings/status',
        name: 'Applicatie status',
        description: 'Check de actuele status van de applicatie',
        icon: 'info'

    }

];

type Props = {
    sidebarOpen: boolean
    setSidebarOpen: Dispatch<SetStateAction<boolean>>
}

const Navbar: FC<Props> = ({ sidebarOpen, setSidebarOpen }): JSX.Element => {


    // Show user from cookie on navbar

    const { user: { profile } } = useCurrentUser();
    const navigate = useNavigate();


    const [ query, setQuery ] = useState('');
    const filteredItems =
        query === ''
        ?[]
        :items.filter((item) => {
            const titleContains = item.name.toLowerCase().includes(query.toLowerCase());
            const descriptionContains = item.description?.toLowerCase().includes(query.toLowerCase());
            return titleContains || descriptionContains;
        });


    const inputRef = useRef<HTMLInputElement>(null);
    const [ showDropDown, setShowDropDown ] = useState(false);


    useEffect(() => {
        if (query !== '') {
            setShowDropDown(true);
            return;
        }
        setShowDropDown(false);
    }, [ query ]);


    return (

        <Combobox value={ query } onChange={ (item) => {
            const currentItem = item as unknown as Item;
            setQuery('');
            filteredItems.splice(0);
            if (inputRef.current) {
                inputRef.current.blur();
            }
            navigate(currentItem.path);
        } }>
            <nav style={ { height: NAVBAR_HEIGHT_STR_IN_REM } } className="navbar sticky top-0 z-40 flex shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white shadow-sm sm:gap-x-6 px-4 sm:px-6 lg:px-8">
                <button type="button" className="-m-2.5 p-2.5 text-gray-700 2xl:hidden" onClick={ () => setSidebarOpen(!sidebarOpen) }>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true"/>
                </button>

                {/* Separator */ }
                <div className="h-6 w-px bg-gray-200 2xl:hidden" aria-hidden="true"/>

                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

                    <div className="relative flex flex-1">
                        <label htmlFor="search-field" className="sr-only">
                            Search
                        </label>
                        <MagnifyingGlassIcon
                            className="pointer-events-none absolute inset-y-0 left-0 ml-5 h-full w-5 text-aqua opacity-50"
                            aria-hidden="true"
                        />

                        <Combobox.Input
                            id="search-field"
                            ref={ inputRef }
                            className="block h-full w-full border-0 py-0 pl-[4.25rem] pr-8 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                            placeholder="Zoeken"
                            type="search"
                            name="search"
                            onChange={ (event) => setQuery(event.target.value) }
                            onBlur={ () => setShowDropDown(false) }
                            onClick={ () => setShowDropDown(query.replace(' ', '') !== '') }
                        />

                        { query.length>0
                          ?<button
                              className={ 'text-2xl text-aqua absolute top-5 opacity-50 hover:cursor-pointer hover:text-graphite-hover right-5' }
                              onClick={ () => {
                                  setQuery('');
                                  filteredItems.splice(0);
                              } }
                          >
                              <span className={ 'material-icons' }>close</span>
                          </button>
                          :<></>
                        }
                    </div>


                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                        {/* Separator */ }
                        <div className="hidden lg:block lg:h-6 lg:w-px 2xl:bg-gray-200" aria-hidden="true"/>

                        {/* Profile dropdown */ }
                        <Menu as="div" className="relative">
                            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                <span className="sr-only">Open user menu</span>
                                <img
                                    className="h-8 w-8 rounded-full bg-gray-50"
                                    src={ profile?.avatarUrl }
                                    alt="profile"
                                />
                                <span className="hidden 2xl:flex 2xl:items-center">
                                    <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                                        { profile?.name }
                                    </span>
                                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true"/>
                                </span>
                            </Menu.Button>
                            <Transition
                                as={ Fragment }
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    { userNavigation.map((item) => (
                                        <Menu.Item key={ item.name }>
                                            { ({ active }) => (
                                                <a
                                                    href={ item.href }
                                                    className={ classNames(
                                                        active ?'bg-gray-50' :'',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    ) }
                                                >
                                                    { item.name }
                                                </a>
                                            ) }
                                        </Menu.Item>
                                    )) }
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </nav>


            <div className={ classNames('bg-white border border-t pb-4 rounded-b-md fixed z-20 top-[3rem] left-[4.5rem] sm:left-[6rem] lg:left-[6.5rem] 2xl:left-[20rem] right-[6.5rem] sm:right-[7rem] lg:right-[10rem] 2xl:right-[15.4rem]',
                showDropDown ?'block' :'hidden'
            ) }>
                { showDropDown && (
                    <Combobox.Options static className={ classNames('max-h-64 scroll-py-3 overflow-y-auto p-2') }>
                        { filteredItems.map(item => (
                            <Combobox.Option
                                key={ item.path }
                                value={ item }
                                className={ ({ active }) =>
                                    classNames('flex cursor-default select-none rounded-lg p-3 hover:cursor-pointer', active ?'bg-aqua bg-opacity-[7.5%]' :'')
                                }
                            >
                                { ({ active }) => (
                                    <>
                                        <div className={ classNames(
                                            'flex h-10 w-10 flex-none items-center justify-center rounded-lg',
                                            active ?'bg-aqua' :'bg-graphite bg-opacity-60'
                                        ) }>
                                            { typeof item.icon === 'string'
                                              ?<span className={ 'material-icons text-lg text-white' }>{ item.icon }</span>
                                              :<item.icon className="h-6 w-6 text-white" aria-hidden="true"/> }
                                        </div>
                                        <div className="ml-4 flex-auto">
                                            <p
                                                className={ classNames(
                                                    'text-sm font-medium',
                                                    active ?'text-graphite' :'text-aqua'
                                                ) }
                                            >
                                                { item.name }
                                            </p>
                                            <p className={ classNames('text-sm', active ?'text-graphite' :'text-aqua') }>
                                                { item.description }
                                            </p>
                                        </div>
                                    </>
                                ) }
                            </Combobox.Option>
                        )) }
                    </Combobox.Options>
                ) }

                { query !== '' && filteredItems.length === 0 && (
                    <div className="py-14 px-6 text-center text-sm sm:px-14">
                        <ExclamationCircleIcon
                            type="outline"
                            name="exclamation-circle"
                            className="mx-auto h-6 w-6 text-fire"
                        />
                        <p className="mt-4 font-semibold text-gray-900">Geen resultaten gevonden.</p>
                        <p className="mt-2 text-gray-500">Probeer het opnieuw.</p>
                    </div>
                ) }
            </div>
        </Combobox>
    );
};
export default Navbar;