/* eslint jsx-a11y/no-autofocus: 0 */

import React, {Fragment, useEffect, useState} from 'react'
import {Listbox, Transition} from '@headlessui/react'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/react/20/solid'
import {Field} from "formik";
import { useResizeDetector } from 'react-resize-detector';
import ErrorMessage from "../Caption/ErrorMessage";
import Label from "../Caption/Label";
import useSelect, {SelectOption} from "../Effects/useSelect";
import Skeleton from "../../Skeleton/Skeleton";
import {classNames} from "../../../modules/Parse/String";
import {requiredFieldClassname} from "../Support/FieldSupport";


type Props<TType> = {
    label: string;
    name: string;
    required?: boolean
    options: SelectOption[],
    multiSelect?: boolean,
    onChange?: (option: TType) => void
    defaultValue?: string|number,
    disabled?: boolean,
    handleValueChange?: (value: string|boolean|number|undefined) => void,
    autoFocus?: boolean,
    description?: string
}

const Select = <TType extends SelectOption | SelectOption[] = SelectOption, >({
    label,
    name,
    required,
    options,
    multiSelect,
    handleValueChange,
    disabled,
    onChange,
    autoFocus,
    description
}: Props<TType>): JSX.Element => {

    const inputSize = useResizeDetector();

    const sortOptions = (options: SelectOption[]) => {
        // @todo evaluate use cases.
        // saleRowForm.charge_id does not need it.
        // Maybe optional property.
        //options.sort((a, b) => `${ a.displayName }`.localeCompare(`${ b.displayName }`)
        return options;
    }

    const {
        selected, setSelected, initialized,
        field, meta, invalid, setOptions
    } = useSelect(name, sortOptions(options), multiSelect);


    const [internalChange, setInternalChange] = useState(false);
    const handleChange = (value: TType) => {
        setSelected(value);
        if (handleValueChange) {
            handleValueChange((value as SelectOption).value)
        }
        if (onChange) {
            onChange(value);
        }
        setInternalChange(true)
    }

    useEffect(() => {
        setOptions(options);
    }, [options]);

    const [prevSelected, setPrevSelected] = useState<SelectOption | SelectOption[]>();
    useEffect(() => {
        if (internalChange) {
            setInternalChange(false);
            return;
        }
        if (selected === undefined) {
            return;
        }
        if ((prevSelected as SelectOption)?.value === (selected as SelectOption)?.value) {
            return;
        }
        if (handleValueChange) {
            handleValueChange((selected as SelectOption).value)
        }
        if (onChange) {
            onChange(selected as TType);
        }
        setPrevSelected(selected);
    }, [selected])

    /**
     *
     */
    const displayValue = () => multiSelect !== true
        ? (selected as SelectOption)?.displayName
        : <>{(selected as SelectOption[])?.map((option, i, arr) =>
            <div key={i} className={'px-2 py-1 inline-block rounded bg-blue-700 text-white text-sm my-0.5 mr-1'}>
                {option?.displayName}
                {arr.length > 1 && <button
                    className="ml-2 text-white align-middle focus:outline-none focus:ring-2 focus:ring-sky focus-visible:ring-offset-2 hover:cursor-pointer"
                    onClick={() => {
                        const newArr = arr.filter((item, j) => i !== j || option.value !== item.value);
                        setSelected(newArr.filter((v, i, a) => a.indexOf(v) === i))
                    }}
                >
                    <span className="material-icons text-sm relative top-0.5">close</span>
                </button>}
            </div>
        ) ?? []}</>


    return initialized ? <>
        <Field
            {...field}
            id={name}
            as={'input'}
            className={'hidden'}
            data-testid={field.name}
            required={required}
            disabled={disabled}
            value={field?.value ?? ''}
        />
        <Listbox multiple={multiSelect === true} value={selected} disabled={disabled} onChange={handleChange}>
            {({open}) => (<>

                <Listbox.Label>
                    <Label invalid={invalid} htmlFor={name}>{label}{required && '*'}</Label>
                </Listbox.Label>
                {description && <span className="text-gray-500 font-normal text-sm">{description}</span>}
                <div ref={inputSize.ref} className="mt-2">

                    <Listbox.Button
                        disabled={disabled}
                        autoFocus={autoFocus === true}
                        className={classNames("relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6",
                        required && requiredFieldClassname,
                        disabled && 'hover:cursor-not-allowed opacity-60',
                    )}>
                        <span className="block truncate">{displayValue()}</span>
                        {!disabled && <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                        </span>}
                    </Listbox.Button>

                    {/* Dropdown */}
                    <Transition show={open} as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                        <Listbox.Options
                            style={{
                                width: `${inputSize.width}px`,
                                height: `calc(${options.length === 0 ? '2.3125rem': (options.length >= 10 ? `${10*2.3125}rem`: `${options.length*2.3125}rem`)} + 0.5rem)`
                            }}
                            className={ 'fixed z-[1] mt-0 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'}
                        >
                            {sortOptions(options).map((option, i) => <Listbox.Option
                                key={i}
                                value={option}
                                className={({active, selected}) => classNames(
                                    selected && 'bg-blue-700',
                                    active && '!bg-blue-600',
                                    (active || selected) ? 'text-white' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9 border-b border-b-white hover:cursor-pointer'
                                )}
                            >
                                {({active, selected}) => (<>
                                    <span className={classNames((selected || active) ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                        {option.displayName}
                                    </span>
                                    {selected && <span className={classNames(active || selected ? 'text-white' : 'text-blue-700', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                        <CheckIcon className="h-5 w-5" aria-hidden="true"/>
                                    </span>}
                                </>)}
                            </Listbox.Option>)}

                        </Listbox.Options>
                    </Transition>

                    <ErrorMessage meta={meta}/>

                </div>
            </>)}
        </Listbox>
    </> : <Skeleton type={"select"}/>
}
export default Select;
