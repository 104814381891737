import { SoftDeleteModel } from '../../../../lib/samfe/types/ModelTypes';
import { ArticleModel } from '../../../article/ArticleTypes';
import { ArticleProducerModel } from '../../../article/pivot/ArticleProducer/ArticleProducerTypes';
import { ChargeModel } from '../../../charge/ChargeTypes';
import { ProductModel } from '../../../product/ProductTypes';
import { PurchaseModel, PurchaseStatusTranslation } from '../../PurchaseTypes';


export type PurchaseRowRelationsBluePrint = (
    'purchase'
    |'purchase.producer'
    |'articleProducer'
    |'article'
    |'article.product'
    |'article.package.parts'
    |'charge'
    |'eta'
    |'delivery_time'
    |'charge.samfeStock'
    |'product'
    );

export type PurchaseRowStatus = 'open'|'sent'|'received'|'booked'
export const PurchaseRowStatuses: PurchaseRowStatus[] = [
    'open', 'sent', 'received', 'booked'
];

export const PurchaseRowStatusDisplayName = PurchaseStatusTranslation;

export type PurchaseRowFields = {
    purchase_id: number,
    article_id: number,
    charge_id: number,
    quantity: number,
    price_per_amount: number,
    comments: string,
    status: PurchaseRowStatus,
    performed_by?: string,
    approved_by?: string,
    expected_delivery_date?: string,
    confirmed_delivery_date?: string,
    product_id: number
}

export type PurchaseRowDto = Partial<PurchaseRowFields>&{
    sent_at?: string|null,
    received_at?: string|null
}

type SpecialParams = {
    eta: number|'-',
    delivery_time: number|'-',
    booked_quantity: number
}

export type PurchaseRowRelations = SpecialParams&{
    purchase: PurchaseModel,
    charge: ChargeModel,
    article: ArticleModel,
    articleProducer: ArticleProducerModel,
    product: ProductModel
}

export type PurchaseRowModel = SoftDeleteModel
    &Partial<PurchaseRowRelations>
    &PurchaseRowDto
    &Partial<SpecialParams>
    &{
    performed_by?: string,
    approved_by?: string
}
