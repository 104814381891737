import React, { FC } from 'react';
import { ExtendTableProps } from '../../../components/table/types/Table';
import BaseChargeTable from '../../charge/BaseChargeTable';

type Props = Omit<ExtendTableProps, 'parentId'> & {
    productNumber?: string,
    productVersion?: number
}
const ProductChargeTable: FC<Props> = ({ productNumber, productVersion }): JSX.Element => {
    return <>
        { productNumber && productVersion && <BaseChargeTable product={ {
            number: productNumber,
            version: productVersion,
        } } detailedView={ true } filterShowAll={ true }/> }
    </>;
};

export default ProductChargeTable;