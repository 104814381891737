import { SaleModel, SaleStatus, SaleStatuses, SaleStatusTranslation } from '../SaleTypes';
import {ChargeModel} from "../../charge/ChargeTypes";
import {ArticleModel} from "../../article/ArticleTypes";
import {SoftDeleteModel} from "../../../lib/samfe/types/ModelTypes";
import {ChargeLocationModel} from "../../location/ChargeLocationTypes";


export type SaleRowRelationsBluePrint = (
    |'article'
    |'article.package'
    |'article.package.parts'
    |'article.package.packageParts.part'
    |'article.product'
    |'charge'
    |'charge.product'
    |'charge.stock'
    |'charge.purchaseRow'
		|'charge.chargeLocations'
    |'charge.chargeLocations.location'
    |'charge.chargeLocations.location.group'
    |'sale'
    |'sale.customer'
    |'financialData'
);

export type SaleRowStatus = SaleStatus
export const SaleRowStatuses: SaleRowStatus[] = SaleStatuses
export const SaleRowStatusTranslation = (status?: SaleRowStatus) => SaleStatusTranslation(status)


export type FinancialSaleRowData = {
    total: number,
    purchase_price: number,
    profit_margin: number,
    profit_margin_percentage: number
}

export type SaleRowFields = {
    sale_id?: number,
    article_id?: number,
    charge_id?: number,
    sku?: string,
    customer_product_reference?: string,
    colli?: number,
    quantity?: number,
    price_per_amount?: number,
    wc_product_id?: string,
    status?: SaleRowStatus,
}

export type SaleRowDto = Partial<SaleRowFields>

export type SaleRowRelations = {
    sale: SaleModel,
    charge: ChargeModel,
    article: ArticleModel,
    financialData: FinancialSaleRowData,
		chargeLocations?: ChargeLocationModel[]
}

export type SaleRowModel = SoftDeleteModel & Partial<SaleRowRelations> & SaleRowDto

